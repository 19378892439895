import "./App.css";
import "../styles/shared.css";

import React, { useEffect, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector, useDispatch } from "react-redux";
import { LOAD_STATUS } from "../constants";
import {
  selectUserStatus,
  selectUserDepartment,
  selectUserRole,
  selectUserId,
  selectOktaInfo,
} from "../Redux/selectors";
import { useOktaAuth } from "../common/useOktaAuth";
import appSlice, { fetchUserLoginInfo } from "../Redux/slices/appSlice";
import { Spin } from "antd";
import LogRocket from "logrocket";
import useSkylineApi from "../hooks/useSkylineApi";
import { isPerformanceDashboard } from "./isPerformanceDashboard";
import { aggregateLoadStatuses } from "../common/util";

const LOGROCKET_PROJECT_ID = process.env.REACT_APP_LOGROCKET_ID;

if (LOGROCKET_PROJECT_ID) {
  LogRocket.init(LOGROCKET_PROJECT_ID);
}

const App = () => {
  const { email, apiToken } = selectOktaInfo(useOktaAuth());
  const skylineApi = useSkylineApi();
  const loadStatus = useSelector(selectUserStatus);

  const userId = useSelector(selectUserId);
  const userRole = useSelector(selectUserRole);
  const department = useSelector(selectUserDepartment);

  const ldClient = useLDClient();
  const [ldLoadStatus, setLdLoadStatus] = useState();

  const dispatch = useDispatch();

  const [appModule, setAppModule] = useState();

  /**
   * This effect runs once to determine if the currently
   * requested app is Skyline or is Performance Dashboard,
   * then dynamically loads the appropriate module
   */
  useEffect(() => {
    if (isPerformanceDashboard()) {
      import("../../performance-dashboard/App").then(setAppModule);
    } else {
      import("./AppMain").then(setAppModule);
    }
  }, []);

  useEffect(() => {
    dispatch(appSlice.actions.setApiToken(apiToken));
    if (
      window.location.origin !== "https://skyline.nymets.com" &&
      window.location.origin !== "https://performance.nymets.com"
    ) {
      console.log(`[DEBUG] API Token = ${apiToken}`);
    }
  }, [apiToken]);

  useEffect(() => {
    // NOTE: this assumes all users have a role and dept
    if (userId && email && userRole && department) {
      LogRocket.identify(userId, {
        name: email,
        email: email,

        // Our custom user variables here
        department: department,
        role: userRole,
      });
    }
  }, [userId, email, userRole, department]);

  useEffect(() => {
    if (loadStatus === LOAD_STATUS.UNINITIALIZED) {
      dispatch(fetchUserLoginInfo({ email, skylineApi }));
    }
  }, [loadStatus]);

  useEffect(() => {
    ldClient
      .waitUntilReady()
      .then(async () => {
        setLdLoadStatus(LOAD_STATUS.LOADING);
        if (
          email !== null &&
          email !== undefined &&
          ldClient &&
          userId !== -1
        ) {
          const ldUser = {
            key: email,
            email: email,
            custom: {
              department: department,
              role: userRole,
            },
          };

          await ldClient.identify(ldUser, null);
          setLdLoadStatus(LOAD_STATUS.READY);
        }
      })
      .catch((err) => {
        console.log(`Caught error while initializing ldclient: ${err}`);
        setLdLoadStatus(LOAD_STATUS.ERROR);
      });
  }, [email, userId]);

  switch (aggregateLoadStatuses([loadStatus, ldLoadStatus])) {
    case LOAD_STATUS.UNINITIALIZED:
    case LOAD_STATUS.LOADING:
      return <Spin className="spinner" size="large" />;
    case LOAD_STATUS.ERROR:
      return <p>Something went wrong loading user login info for the app...</p>;
    case LOAD_STATUS.READY:
    default:
      // if the top level effect has completed and we have a loaded module,
      // then show it, otherwise, render the spinner.
      return appModule ? (
        <appModule.default />
      ) : (
        <Spin className="spinner" size="large" />
      );
  }
};

export default App;
