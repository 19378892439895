import React from "react";
import {
  TextField,
  InputAdornment,
  TextFieldProps,
  Backdrop,
} from "@mui/material";
import Box from "@mui/material/Box";
// eslint-disable-next-line no-unused-vars
import { Spin, SpinProps } from "antd";

export const TextFieldWithCount = ({
  variant = "outlined",
  size = "small",
  maxLength,
  ...props
}: TextFieldProps & { maxLength: number; value?: string }) => (
  <TextField
    variant={variant}
    size={size}
    inputProps={{
      maxLength,
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {props.value?.length ?? 0}/{maxLength}
        </InputAdornment>
      ),
    }}
    {...props}
  />
);

export function LoadingOverlay({
  isLoading,
  size,
  opacity = 0.03,
}: {
  isLoading: boolean;
  size?: SpinProps["size"];
  opacity?: number;
}) {
  return (
    <Backdrop
      sx={{
        position: "absolute",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: `rgba(0, 0, 0, ${opacity})`,
      }}
      open={isLoading}
    >
      <Box sx={{ p: 2 }}>
        <Spin size={size} />
      </Box>
    </Backdrop>
  );
}
