import { configureStore } from "@reduxjs/toolkit";
import userAdminReducer from "./slices/userAdminSlice";
import playerReducer from "./slices/playerSlice";
import teamReducer from "./slices/teamSlice";
import appSlice from "./slices/appSlice";
import { emptySkylineApi } from "../../services/emptySkylineApi";
import { mlbStatsApi } from "../../services/mlbStatsApi";

export const buildStore = (
  extraStoreConfig = { reducer: {}, middlewares: [] }
) =>
  configureStore({
    reducer: {
      app: appSlice.reducer,
      userAdmin: userAdminReducer,
      player: playerReducer,
      team: teamReducer,
      [emptySkylineApi.reducerPath]: emptySkylineApi.reducer,
      [mlbStatsApi.reducerPath]: mlbStatsApi.reducer,
      ...extraStoreConfig.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(extraStoreConfig.middlewares)
        .concat(emptySkylineApi.middleware),
  });
