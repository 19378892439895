import dayjs from "dayjs";
import MinMax from "dayjs/plugin/minMax";
import { tabs } from "../tabs";
import {
  Side,
  findOrDefaultMockDataByPlayer,
  truncateNumberToDecimals,
} from "../utils/metrics";
import { MovementVideo } from "../MovementVideo";
import { flatten, mean } from "lodash";
import { truncToDecimalPlaces } from "../utils/numbers";
import { SelfCompSelectedValue } from "../components/common/SelfMenu";
import { MotionType } from "../components/common/MotionType";

dayjs.extend(MinMax);

export interface Level {
  id: number;
  name: string;
  defaultLevel?: boolean;
}

export interface Org {
  id: number;
  name: string;
  teams: OrgTeam[];
}

export interface OrgTeam {
  mlbam_id: number;
  mets_id: string;
  level: number;
  name: string;
  abbreviation: string;
}

export interface Position {
  id: number;
  name: string;
}

export const LEVELS: Level[] = [
  { id: 1, name: "MLB" },
  { id: 2, name: "AAA (Syracuse)" },
  { id: 3, name: "AA (Binghamton)" },
  { id: 4, name: "A+ (Brooklyn)" },
  { id: 5, name: "A- (St Lucie)" },
  { id: 6, name: "Rk (FCL)" },
  { id: 7, name: "DSL (Mets 1)" },
  { id: 8, name: "DSL (Mets 2)" },
  { id: 9, name: "Amateur" },
];

export interface Team {
  id: number;
  name: string;
  level: Level;
}

export const TEAMS = [
  {
    id: 1,
    level: findById(1, LEVELS),
    name: "New York Mets",
  },
  {
    id: 1,
    level: findById(1, LEVELS),
    name: "New York Mets B",
  },
  {
    id: 2,
    level: findById(2, LEVELS),
    name: "Triple A Team",
  },
];

export interface Player {
  id: number | string;
  metsId?: string;
  team?: Team;
  name: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  throws?: string;
  bats?: string;
  hasTrackedPitches?: boolean;
  displayValue?: string;
  value?: string;
  mostRecentTrackedPitchDate?: string;
  path?: string;
  metrics?: any[];
  //computed
  handedness?: Side;
  primaryPosition?: string;
}

export const PLAYERS: Player[] = [
  { id: 1, team: findById(1, TEAMS), name: "Tylor Megill" },
  { id: 2, team: findById(2, TEAMS), name: "Daison Acosta" },
  { id: 3, team: findById(2, TEAMS), name: "Eris Albino" },
];

export const ORGS: Org[] = [
  { id: 1, name: "Arizona Diamondbacks", teams: [] },
  { id: 2, name: "Atlanta Braves", teams: [] },
  { id: 3, name: "Baltimore Orioles", teams: [] },
  { id: 4, name: "Boston Red Sox", teams: [] },
  { id: 5, name: "Chicago Cubs", teams: [] },
  { id: 6, name: "Chicago White Sox", teams: [] },
];

export const POSITIONS: Position[] = [
  { id: 1, name: "Pitching" },
  { id: 2, name: "Batting" },
];

export enum SourcePitch {
  Hawkeye = "Hawkeye(g)",
  KinatraxG = "Kinatrax(g)",
  LabMocap = "Lab Mocap",
  LabKinatrax = "Lab Kinatrax",
  KinatraxNG = "Kinatrax(ng)",
}

export interface Pitch {
  id: number;
  playerId: number;
  source: SourcePitch;
  description: string;
  date: string;
  name: string;
  strikesOrOuts?: number;
  type: string;
  speed: number;
  strikeZonePosition: { x: number; y: number };
  index: number;
  strikes?: number;
  outs?: number;
  inning?: number;
  horizontalBreak?: number;
  inducedVerticalBreak?: number;
  videos?: MovementVideo[];
  //Swings
  outcome?: string;
  hitSpeed?: number;
  launchAngle?: number;
  pa_of_batter?: number;
  pitch_of_pa?: number;
  batter_side?: string;
  pitch_result?: string;
  batter?: {
    bats: string;
    side: string;
  };
  //Avg files
  originalAvgFile?: any;
}

export interface BodyPartMetric {
  id: number;
  name: string;
  bodyPart: string;
  unit: string;
  events: BodyPartMetricEvents;
  data: number[];
  dataComp?: any[];
}
export interface BodyPartMetricEvents {
  mkh: number;
  hs: number;
  fc: number;
  mer: number;
  br: number;
  max: number;
}

export enum BodyPartTabs {
  ThrowingArm = "Throwing arm",
  GloveArm = "Glove arm",
  TrunkAndPelvis = "Trunk and pelvis",
  LeadLeg = "Lead leg",
  BackLeg = "Back leg",
  PelvisTrunk = "Pelvis/Trunk",
  SpineAndShoulder = "Spine & Shoulder",
  ElbowAndWrist = "Elbow & Wrist",
  HeadAndHand = "Head & Hand",
}

export type PitchingBodyPartTabs = Exclude<
  BodyPartTabs,
  | BodyPartTabs.PelvisTrunk
  | BodyPartTabs.SpineAndShoulder
  | BodyPartTabs.ElbowAndWrist
  | BodyPartTabs.HeadAndHand
>;

export type BattingBodyPartTabs = Exclude<
  BodyPartTabs,
  BodyPartTabs.ThrowingArm | BodyPartTabs.GloveArm | BodyPartTabs.TrunkAndPelvis
>;

export interface ThrowEvent {
  id: number;
  pitchId: number;
  value: number;
  averageRange: {
    from: number;
    to: number;
  };
  metricName: string;
  label: string;
  description: string;
  min: number;
  max: number;
  unit: string;
}

export interface HistoryEvent {
  id: number;
  playerId: number;
  value: number;
  averageRange: {
    from: number;
    to: number;
  };
  metricName: string;
  label: string;
  description: string;
  min: number;
  max: number;
  unit: string;
}

export interface DataSwitch {
  label: string;
  id: number;
  year?: string;
  mph?: string;
  key: string;
}

export interface PlayerSummary {
  metric?: string;
  label: string;
  collapsedLabel?: string;
  value: number;
  currentTarget?: number;
  rank?: number;
  percentile?: number;
  previousValue?: number;
}
export interface TeamPlayer extends Player {
  status: string;
  currentSchoolName?: string;
  flags?: number[];
  metrics: TeamPlayerMetrics[];
}

export type DELTA_TREND_TYPES = "up" | "down" | "none";

export interface TeamPlayerMetrics {
  key: string;
  metric: METRICS_PLAYER | string;
  value: string;
  variabledb?: string;
  previousValue?: number;
  percentile?: number;
  delta?: number;
  deltaTrend?: DELTA_TREND_TYPES;
  rank?: number;
  rankDenominator?: number;
  date?: string;
  thresholds?: number[];
}

export enum METRICS_PLAYER {
  Msk = "msk",
  Rom = "rom",
  Power = "power",
  Speed = "speed",
  BodyComp = "body_comp",
  Vision = "vision",
  Workload = "workload",
}

export const PITCHES: Pitch[] = [
  {
    id: 1,
    playerId: 1,
    source: SourcePitch.KinatraxG,
    description: "Kinatrax(g) Pitch",
    date: dayjs().startOf("month").add(18, "days").toDate().toISOString(),
    name: "Kinatrax(g) Pitch",
    strikesOrOuts: 1,
    type: "4FB",
    speed: 92.0,
    strikeZonePosition: { x: 1, y: 1 },
    index: 1,
  },
  {
    id: 2,
    playerId: 1,
    source: SourcePitch.Hawkeye,
    description: "Hawkeye(g) Pitch",
    date: dayjs().startOf("month").add(19, "days").toDate().toISOString(),
    name: "Hawkeye(g) Pitch",
    strikesOrOuts: 1,
    type: "4FB",
    speed: 97.0,
    strikeZonePosition: { x: 1, y: 1 },
    index: 2,
  },
  /*{
    id: 3,
    playerId: 1,
    source: SourcePitch.KinatraxG,
    description: "Kinatrax(g) Pitch",
    date: dayjs().startOf("month").add(20, "days").toDate().toISOString(),
    name: "Kinatrax(g) Pitch",
    strikesOrOuts: 1,
    type: "4FB",
    speed: 93.2,
    strikeZonePosition: { x: 1, y: 1 },
    index: 3,
  },*/
];

export type PitchingBodyPartMetric = Record<PitchingBodyPartTabs, string[]>;

export const PITCHING_BODY_PARTS_METRICS: PitchingBodyPartMetric = {
  [BodyPartTabs.ThrowingArm]: [
    "Throwing Elbow Flexion",
    "Throwing Shoulder Rotation",
    "Throwing Shoulder Elevation",
    "Throwing Shoulder Horizontal Abduction",
  ],
  [BodyPartTabs.GloveArm]: [
    "Lead Elbow Flexion",
    "Lead Shoulder Rotation",
    "Lead Shoulder Abduction",
    "Lead Shoulder Horizontal Abduction",
    "Lead Arm Momentum",
    "Arm Momentum Direction",
  ],
  [BodyPartTabs.TrunkAndPelvis]: [
    "Pelvis Rotation",
    "Pelvis Lateral Tilt",
    "Trunk Rotation",
    "Trunk Lateral Tilt",
    "Trunk Flexion",
    "Spine Rotation",
    "Spine Flexion",
    "Pelvis Velocity (X-Direction)",
  ],
  [BodyPartTabs.LeadLeg]: [
    "Lead Hip Rotation",
    "Lead Hip Abduction",
    "Lead Hip Flexion",
    "Lead Knee Flexion",
    "Lead Ankle Flexion",
    "Lead Foot Angle",
    "Lead Knee Extension Velocity",
    "Ankle Extension Velocity",
    "Knee Excursion (Frontal)",
  ],
  [BodyPartTabs.BackLeg]: [
    "Back Hip Rotation",
    "Back Hip Abduction",
    "Back Hip Flexion",
    "Back Knee Flexion",
    "Back Ankle Flexion",
    "Back Foot Angle",
    "Back Shin Vertical Angle (Sagittal)",
    "Ankle Extension Velocity",
    "Shin Angle (Side)",
    "Shin Angle Velocity (Side)",
  ],
};

export type BattingBodyPartMetric = Record<BattingBodyPartTabs, string[]>;

export const BATTING_BODY_PARTS_METRICS: BattingBodyPartMetric = {
  [BodyPartTabs.BackLeg]: [
    "Back Ankle Flexion",
    "Back Knee Flexion",
    "Back Hip Rotation",
    "Back Hip Flexion",
    "Back Hip Abduction",
  ],
  [BodyPartTabs.LeadLeg]: [
    "Front Ankle Flexion",
    "Front Knee Flexion",
    "Front Hip Rotation",
    "Front Hip Flexion",
    "Front Hip Abduction",
    "Front Knee Extension Velocity",
  ],
  [BodyPartTabs.PelvisTrunk]: [
    "Pelvis Rotation",
    "Pelvis Flexion",
    "Pelvis Lateral Tilt",
    "Trunk Rotation",
    "Trunk Flexion",
    "Trunk Lateral Tilt",
  ],
  [BodyPartTabs.SpineAndShoulder]: [
    "Spine Rotation",
    "Spine Flexion",
    "Spine Lateral Tilt",
    "Back Shoulder Rotation",
    "Front Shoulder Horizontal Abduction",
  ],
  [BodyPartTabs.ElbowAndWrist]: [
    "Back Elbow Flexion", //TODO: Check this one.
    "Back Wrist Flexion",
    "Back Wrist Pronation",
    "Back Wrist Ulnar Deviation",
  ],
  [BodyPartTabs.HeadAndHand]: [
    "Hand Position x",
    "Hand Position y",
    "Hand Position y",
    "Head Position x",
    "Position z",
    "Head Position y",
  ],
};

export const PITCHING_BODY_PARTS_EVENTS = [
  "MKH",
  "MS",
  "FFC",
  "MER",
  "BR",
  "MAX",
];

export const BATTING_BODY_PARTS_EVENTS = ["SET", "LFP", "DS", "BC"];

function findById(id: number, array: any[]) {
  return array.find((item) => item.id === id);
}

export const initMovement = () => ({
  level: { id: 1, name: "Major League Baseball" },
  player: null,
  date: dayjs(new Date()).toISOString(),
  trial: null,
  org: null,
  position: null,
});

const DEFAULT_RANGE_VALUE = {
  id: 1,
  value: 59,
  averageRange: { from: 60, to: 80 },
  metricName: "RubberPosition",
  label: "Rubber position",
  description: "“ to the left",
  min: 50,
  max: 90,
  unit: "“",
};

export const KNEE_HEIGHTS = [
  {
    id: 1,
    pitchId: 1,
    value: 59,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    pitchId: 1,
    value: 78,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    pitchId: 2,
    value: 52,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 6,
    pitchId: 2,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 7,
    pitchId: 2,
    value: 88,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 8,
    pitchId: 2,
    value: 86,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 9,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 10,
    pitchId: 2,
    value: 86,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const HAND_SEPARATION = [
  {
    id: 1,
    pitchId: 1,
    value: 65,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    pitchId: 1,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    pitchId: 1,
    value: 80,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    pitchId: 2,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 6,
    pitchId: 2,
    value: 65,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 7,
    pitchId: 2,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 8,
    pitchId: 2,
    value: 79,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 9,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 10,
    pitchId: 2,
    value: 65,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const FOOT_CONTACT = [
  {
    id: 1,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    pitchId: 1,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    pitchId: 1,
    value: 65,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    pitchId: 2,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 6,
    pitchId: 2,
    value: 65,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 7,
    pitchId: 2,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 8,
    pitchId: 2,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 9,
    pitchId: 1,
    value: 80,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 10,
    pitchId: 2,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const MAX_EXT_ROT = [
  {
    id: 1,
    pitchId: 1,
    value: 59,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    pitchId: 1,
    value: 78,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    pitchId: 2,
    value: 52,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 6,
    pitchId: 2,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 7,
    pitchId: 2,
    value: 88,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 8,
    pitchId: 2,
    value: 86,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 9,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 10,
    pitchId: 2,
    value: 86,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const REALESE = [
  {
    id: 1,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    pitchId: 1,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    pitchId: 1,
    value: 65,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    pitchId: 2,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 6,
    pitchId: 2,
    value: 65,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 7,
    pitchId: 2,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 8,
    pitchId: 2,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 9,
    pitchId: 1,
    value: 80,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 10,
    pitchId: 2,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const FOLLOW_THROUGH = [
  {
    id: 1,
    pitchId: 1,
    value: 59,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    pitchId: 1,
    value: 78,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    pitchId: 2,
    value: 52,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 6,
    pitchId: 2,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 7,
    pitchId: 2,
    value: 88,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 8,
    pitchId: 2,
    value: 86,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 9,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 10,
    pitchId: 2,
    value: 86,
    averageRange: { from: 60, to: 80 },
    metricName: "RubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const STUFF = [...KNEE_HEIGHTS, ...KNEE_HEIGHTS].map((item, id) => ({
  ...item,
  id: id + 1,
}));

export const SEQUENCE_RANGE_DATA = [
  {
    id: 1,
    pitchId: 1,
    value: 59,
    averageRange: { from: 60, to: 80 },
    metricName: "PelvisRotationVelo",
    label: "Pelvis Rotation Velo",
    description: "(deg/s)",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 2,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "",
    label: "",
    description: "",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 3,
    pitchId: 1,
    value: 78,
    averageRange: { from: 60, to: 80 },
    metricName: "TrunkRotationVelo",
    label: "Trunk Rotation Velo",
    description: "(deg/s)",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 4,
    pitchId: 1,
    value: 85,
    averageRange: { from: 60, to: 80 },
    metricName: "",
    label: "",
    description: "",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 5,
    pitchId: 1,
    value: 52,
    averageRange: { from: 60, to: 80 },
    metricName: "TrunkFlexionVelo",
    label: "Trunk Flexion Velo",
    description: "(deg/s)",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 6,
    pitchId: 1,
    value: 70,
    averageRange: { from: 60, to: 80 },
    metricName: "",
    label: "",
    description: "",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 7,
    pitchId: 2,
    value: 72,
    averageRange: { from: 60, to: 80 },
    metricName: "PelvisRotationVelo",
    label: "Pelvis Rotation Velo",
    description: "(deg/s)",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 8,
    pitchId: 2,
    value: 62,
    averageRange: { from: 60, to: 80 },
    metricName: "",
    label: "",
    description: "",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 9,
    pitchId: 2,
    value: 80,
    averageRange: { from: 60, to: 80 },
    metricName: "TrunkRotationVelo",
    label: "Trunk Rotation Velo",
    description: "(deg/s)",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 10,
    pitchId: 2,
    value: 58,
    averageRange: { from: 60, to: 80 },
    metricName: "",
    label: "",
    description: "",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 11,
    pitchId: 2,
    value: 75,
    averageRange: { from: 60, to: 80 },
    metricName: "TrunkFlexionVelo",
    label: "Trunk Flexion Velo",
    description: "(deg/s)",
    min: 50,
    max: 90,
    unit: "º",
  },
  {
    id: 12,
    pitchId: 2,
    value: 53,
    averageRange: { from: 60, to: 80 },
    metricName: "",
    label: "",
    description: "",
    min: 50,
    max: 90,
    unit: "º",
  },
];

export const SEQUENCE_RANGE = [...SEQUENCE_RANGE_DATA].map((item, id) => ({
  ...item,
  id: id + 1,
}));

export const HISTORY_KNEE_HEIGHTS = [
  {
    id: 1,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const HISTORY_MID_STRIDE = [
  {
    id: 1,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const HISTORY_FOOT_CONTACT = [
  {
    id: 1,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const HISTORY_MAX_EXT_ROT = [
  {
    id: 1,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const HISTORY_REALESE = [
  {
    id: 1,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const HISTORY_FOLLOW_THROUGH = [
  {
    id: 1,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 2,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 3,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 4,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
  {
    id: 5,
    playerId: 1,
    value: 60,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    label: "Rubber position",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  },
];

export const HISTORY_STUFF = [
  ...HISTORY_KNEE_HEIGHTS,
  ...HISTORY_KNEE_HEIGHTS,
].map((item, id) => ({
  ...item,
  id: id + 1,
}));

export const TIME_SERIES = [
  {
    id: 1,
    pitchId: 1,
    label: "Metric 1",
    data: [34, 56, 65, 27, 43, 83, 33, 74, 57, 54, 10],
    unit: "°",
    min: 50,
    max: 90,
    averageRange: { from: 60, to: 80 },
    value: 63,
  },
  {
    id: 2,
    pitchId: 1,
    label: "Metric 2",
    data: [24, 37, 29, 29, 32, 30, 38, 36, 33, 34, 31],
    unit: "ft",
    min: 50,
    max: 90,
    averageRange: { from: 60, to: 80 },
    value: 58,
  },
  {
    id: 3,
    pitchId: 2,
    label: "Metric 3",
    data: [11, 30, 16, 19, 20, 24, 32, 30, 29, 29, 25],
    unit: "°",
    min: 50,
    max: 90,
    averageRange: { from: 60, to: 80 },
    value: 73,
  },
  {
    id: 4,
    pitchId: 2,
    label: "Metric 4",
    data: [21, 5, 8, 11, 8, 11, 18, 17, 13, 11, 10],
    unit: "ft",
    min: 50,
    max: 90,
    averageRange: { from: 60, to: 80 },
    value: 81,
  },
];

export const HISTORY_TIME_SERIES = [
  {
    id: 1,
    playerId: 1,
    label: "Self Metric",
    unit: "°",
    data: [44, 66, 75, 37, 53, 93, 43, 84, 67, 64, 20],
    min: 50,
    max: 90,
    averageRange: { from: 60, to: 80 },
    value: 78,
  },
];

export const COMP_DATA_TIME_SERIES = [
  {
    id: 1,
    playerId: 1,
    label: "Comp Data Metric",
    unit: "°",
    min: 50,
    max: 90,
    averageRange: { from: 60, to: 80 },
    value: 70,
    data: [
      [43.7, 55.6],
      [43.3, 51.8],
      [41.2, 49.9],
      [37.9, 47.3],
      [34.9, 50.6],
      [35.1, 46.8],
      [39.3, 51.1],
      [41.1, 50.5],
      [38.9, 48.4],
      [34.6, 53.2],
      [41.5, 56],
      [38.6, 53.4],
      [39.8, 52.2],
      [38.1, 48.2],
      [35.9, 50.2],
      [34.5, 50.2],
      [38.9, 49.8],
      [41.1, 52.1],
      [37.9, 56.7],
      [45.9, 58.6],
      [44.9, 57.5],
      [39.5, 56],
      [41.5, 52.4],
      [38.6, 51.1],
      [42.9, 51.7],
      [43.6, 50.9],
      [39.6, 53.9],
      [38.6, 52.7],
      [37.5, 55.7],
      [35.5, 54.3],
      [40.4, 51.2],
    ],
  },
];

export const HISTORY_BODY_PART = [
  {
    id: 1,
    playerId: 1,
    label: "Self Metric",
    unit: "°",
    data: [44, 66, 75, 37, 53, 93, 43, 84, 67, 64, 20],
  },
];

export const COMP_DATA_BODY_PART = [
  {
    id: 1,
    playerId: 1,
    label: "Comp Data Metric",
    unit: "°",
    data: [
      [43.7, 55.6],
      [43.3, 51.8],
      [41.2, 49.9],
      [37.9, 47.3],
      [34.9, 50.6],
      [35.1, 46.8],
      [39.3, 51.1],
      [41.1, 50.5],
      [38.9, 48.4],
      [34.6, 53.2],
      [41.5, 56],
      [38.6, 53.4],
      [39.8, 52.2],
      [38.1, 48.2],
      [35.9, 50.2],
      [34.5, 50.2],
      [38.9, 49.8],
      [41.1, 52.1],
      [37.9, 56.7],
      [45.9, 58.6],
      [44.9, 57.5],
      [39.5, 56],
      [41.5, 52.4],
      [38.6, 51.1],
      [42.9, 51.7],
      [43.6, 50.9],
      [39.6, 53.9],
      [38.6, 52.7],
      [37.5, 55.7],
      [35.5, 54.3],
      [40.4, 51.2],
    ],
  },
];

interface STUFF_DEFAULT {
  key: string;
  label: string;
  dataComp?: string;
  description: string;
  unit: string;
  decimals: number;
  systematicName: string;
}

const defaultRound = (value: number) => truncToDecimalPlaces(value, 1);
const stuffFormatValue = (value: number) => value * -600 * 3.9;
const stuffFormatRange = (value: number) => 0 - stuffFormatValue(value);

const stuffParseValue: any = {
  quality: (value: number) => truncToDecimalPlaces(stuffFormatValue(value), 2),
  spinEfficiency: (value: number) => defaultRound(value * 100),
  spinRate: truncToDecimalPlaces,
  stuff: (value: number) => defaultRound(stuffFormatValue(value)),
  releaseSide: defaultRound,
};

const stuffParseRangeValues: any = {
  inducedVerticalBreak: defaultRound,
  horizontalBreak: defaultRound,
  spinEfficiency: (value: number) => defaultRound(value * 100),
  stuff: (value: number) => defaultRound(stuffFormatRange(value)),
  releaseExtension: defaultRound,
  releaseSide: defaultRound,
  releaseHeight: defaultRound,
  quality: (value: number) => truncToDecimalPlaces(stuffFormatRange(value), 2),
};

const STUFF_DATA: STUFF_DEFAULT[] = [
  {
    key: "speed",
    dataComp: "rel_speed",
    label: "Velocity",
    description: "mph",
    unit: "mph",
    decimals: 1,
    systematicName: "rel_speed",
  },
  {
    key: "spinRate",
    dataComp: "pitch_spin_rate",
    label: "Spin Rate",
    description: "rpm",
    unit: "º",
    decimals: 1,
    systematicName: "hit_spin_rate",
  },
  {
    key: "spinEfficiency",
    dataComp: "spin_efficiency",
    label: "Spin Efficiency",
    description: "%",
    unit: "%",
    decimals: 1,
    systematicName: "spin_efficiency",
  },
  {
    key: "spinAxis",
    dataComp: "spin_axis",
    label: "Spin Axis",
    description: "º",
    unit: "º",
    decimals: 1,
    systematicName: "spin_axis",
  },
  {
    key: "inducedVerticalBreak",
    dataComp: "pitch_IVB",
    label: "Ind. Vertical Break",
    description: "in",
    unit: `"`,
    decimals: 1,
    systematicName: "vert_break_induced",
  },
  {
    key: "horizontalBreak",
    dataComp: "pitch_HB",
    label: "Horizontal Break",
    description: "in",
    unit: `"`,
    decimals: 1,
    systematicName: "horz_break",
  },
  {
    key: "releaseSide",
    dataComp: "rel_side",
    label: "Release Side",
    description: "ft",
    unit: "'",
    decimals: 1,
    systematicName: "rel_side",
  },
  {
    key: "releaseHeight",
    dataComp: "rel_height",
    label: "Release Height",
    description: "ft",
    unit: "'",
    decimals: 1,
    systematicName: "rel_height",
  },
  {
    key: "releaseExtension",
    dataComp: "pitch_extension",
    label: "Release Extension",
    description: "ft",
    unit: "ft",
    decimals: 1,
    systematicName: "extension",
  },
  {
    key: "ArmSlot_Apex",
    dataComp: "ArmSlot_Apex",
    label: "Arm Slot",
    description: "°",
    unit: "°",
    decimals: 1,
    systematicName: "ArmSlot_Apex",
  },
  {
    key: "stuff",
    dataComp: "stuff",
    label: "Stuff",
    description: "",
    unit: "",
    decimals: 1,
    systematicName: "stuff",
  },
  {
    key: "quality",
    dataComp: "prv",
    label: "pRV",
    description: "",
    unit: "",
    decimals: 1,
    systematicName: "prv",
  },
];
//TODO: Replace with actual batting variables
const BATTING_STUFF_DATA: STUFF_DEFAULT[] = [
  {
    key: "hit_speed",
    dataComp: "exit_velocity",
    label: "Exit Velocity",
    description: "mph",
    unit: "mph",
    decimals: 1,
    systematicName: "exit_velocity",
  },
  {
    key: "hit_vert_angle",
    dataComp: "vertical_launch_angle",
    label: "Vertical Launch Angle",
    description: "degrees",
    unit: "degrees",
    decimals: 1,
    systematicName: "vertical_launch_angle",
  },
  {
    key: "hit_horz_angle",
    dataComp: "horizontal_launch_angle",
    label: "Horizontal Launch Angle",
    description: "degrees",
    unit: "degrees",
    decimals: 1,
    systematicName: "horizontal_launch_angle",
  },
  {
    key: "hit_distance",
    dataComp: "hit_distance",
    label: "Hit Distance",
    description: "feet",
    unit: "feet",
    decimals: 1,
    systematicName: "hit_distance",
  },
  {
    key: "hit_spin_rate",
    dataComp: "hit_spin_rate",
    label: "Hit Spin Rate",
    description: "rpm",
    unit: "rpm",
    decimals: 1,
    systematicName: "hit_spin_rate",
  },
  {
    key: "hang_time",
    dataComp: "hit_hang_time",
    label: "Hit Hang Time",
    description: "seconds",
    unit: "seconds",
    decimals: 1,
    systematicName: "hit_hang_time",
  },
  {
    key: "pitch_xrv",
    dataComp: "xrv",
    label: "xRV",
    description: "",
    unit: "",
    decimals: 1,
    systematicName: "xrv",
  },
  {
    key: "batting_xrvcon",
    dataComp: "xrvcon",
    label: "xRVcon",
    description: "",
    unit: "",
    decimals: 1,
    systematicName: "xrvcon",
  },
];
export const SEQUENCE = TIME_SERIES;
export const HISTORY_SEQUENCE = HISTORY_TIME_SERIES;
export const COMP_DATA_SEQUENCE = COMP_DATA_TIME_SERIES;

export const throwEventDataByPitch = (
  pitch: Pitch,
  eventData: ThrowEvent[]
) => {
  const mainEventData = eventData.filter(({ pitchId }) => pitchId === 1);
  const comparingEventData = eventData.filter(({ pitchId }) => pitchId === 2);

  return (
    { 1: mainEventData, 2: comparingEventData }[pitch.id] || comparingEventData
  );
};

export const stuffByPitch = (
  pitch: any,
  discreteDataComp: any[],
  motionType: MotionType
) => {
  const stuffData =
    motionType === MotionType.Batting ? BATTING_STUFF_DATA : STUFF_DATA;
  return stuffData.map((data: STUFF_DEFAULT, index) => {
    const dataComp = discreteDataComp.find(
      (discrete) => discrete.id === data.dataComp
    );
    const { max, min, averageRange } = DEFAULT_RANGE_VALUE;
    const rangeParseFunction =
      stuffParseRangeValues[data.key] || truncToDecimalPlaces;
    const MAX = rangeParseFunction(dataComp?.max || max);
    const MIN = rangeParseFunction(dataComp?.min || min);
    const AVG = dataComp?.averageRange || averageRange;
    const value = stuffParseValue[data.key]
      ? stuffParseValue[data.key](pitch?.[data.key])
      : defaultRound(pitch?.[data.key] || dataComp?.value);

    return {
      ...DEFAULT_RANGE_VALUE,
      ...data,
      id: index,
      value: truncateNumberToDecimals(value, data.decimals),
      max: MAX,
      min: MIN,
      mean: rangeParseFunction(mean([AVG.from, AVG.to])),
      averageRange: {
        from: rangeParseFunction(AVG.from),
        to: rangeParseFunction(AVG.to),
      },
    };
  });
};

export const sequenceRangeByPitch = (pitch: Pitch) => {
  const mainStuff: any[] = SEQUENCE_RANGE.filter(
    ({ pitchId }) => pitchId === pitch.id
  );
  const comparingStuff: any[] = SEQUENCE_RANGE.filter(
    ({ pitchId }) => pitchId === 2
  );

  return { 1: mainStuff, 2: comparingStuff }[pitch.id] || comparingStuff;
};

export const timeSeriesByPitch = (pitch: Pitch) => {
  const mainTimeSeries: any[] = TIME_SERIES.filter(
    ({ pitchId }) => pitchId === 1
  );
  const comparingTimeSeries: any[] = TIME_SERIES.filter(
    ({ pitchId }) => pitchId === 2
  );

  return (
    { 1: mainTimeSeries, 2: comparingTimeSeries }[pitch.id] ||
    comparingTimeSeries
  );
};

export const selfEventDataByPlayer = (
  player: Player,
  history: HistoryEvent[]
) => history.filter(({ playerId }) => player.id === playerId);

export const selfStuffByPlayer = (player: Player) =>
  findOrDefaultMockDataByPlayer(player, HISTORY_STUFF);

export const coordinatesUrlByPitch = (pitch: Pitch) => {
  const pitch1Coordinates =
    "3d/data/megill/Megill_Tylor_2023/cbma_motion_sequence.csv";
  const pitch2Coordinates =
    "3d/data/megill/Megill_Tylor_2022/cbma_motion_sequence.csv";
  const comparingCoordinatesUrl = "3d/data/cbma_motion_sequence.csv";
  return (
    {
      "1": pitch1Coordinates,
      "2": pitch2Coordinates,
      dac640af38281bd: pitch1Coordinates,
      "2fe9db35dc1e793": pitch1Coordinates,
    }[pitch.id] || comparingCoordinatesUrl
  );
};

export const selfTimeSeriesByPlayer = (player: Player) =>
  findOrDefaultMockDataByPlayer(player, HISTORY_TIME_SERIES);

export const selfBodyPartByPlayer = (player: Player) =>
  findOrDefaultMockDataByPlayer(player, HISTORY_BODY_PART);

export const dataCompTimeSeriesByPlayer = (player: Player) =>
  findOrDefaultMockDataByPlayer(player, COMP_DATA_TIME_SERIES);

export const dataCompBodyPartByPlayer = (player: Player) =>
  findOrDefaultMockDataByPlayer(player, COMP_DATA_TIME_SERIES);

const generateSampleVideos = (pitches: boolean, pitch?: Pitch) =>
  tabs.map((tab, i) => ({
    angle: tab,
    videoUrl: pitches
      ? `/3d/data/${
          pitch
            ? ["1", "dac640af38281bd", "2fe9db35dc1e793"].includes(
                pitch.id.toString()
              )
              ? "megill/Megill_Tylor_2023/"
              : "megill/Megill_Tylor_2022/"
            : ""
        }${tab}.mp4`
      : i % 2 !== 0
      ? "https://performance-technology-3d.netlify.app/.mp4"
      : "/3d/data/pitch.mp4",
  }));
const adaptMockVideos = (pitchVideos: any[], omitFfmpeg = false) =>
  pitchVideos
    .filter((it) => it.videoUrl.includes("skeleton"))
    .map((it) => ({
      videoUrl:
        omitFfmpeg || it.videoUrl.includes("_ffmpeg.mp4")
          ? it.videoUrl
          : it.videoUrl.replace(".mp4", "_ffmpeg.mp4"),
      angle: it.angle === "C" ? "High CF" : it.angle == "RF" ? "OB" : it.angle,
    }));
export const videosByPitch = (pitch: Pitch) => {
  return (
    {
      "1": generateSampleVideos(true, pitch),
      "2": generateSampleVideos(true, pitch),
      dac640af38281bd_test: generateSampleVideos(true, pitch),
      dac640af38281bd: adaptMockVideos([
        {
          angle: "RF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340174/340174.filtered.mp4",
        },
        {
          angle: "ffff3BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340170/340170.mp4",
        },
        {
          angle: "LF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340175/340175.skeleton.mp4",
        },
        {
          angle: "LF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340175/340175.mp4",
        },
        {
          angle: "3BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340170/340170.skeleton.mp4",
        },
        {
          angle: "C",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340042/340042.filtered.mp4",
        },
        {
          angle: "3D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340172/340172.filtered.mp4",
        },
        {
          angle: "1BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340041/340041.skeleton.mp4",
        },
        {
          angle: "CF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340141/340141.mp4",
        },
        {
          angle: "1D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340173/340173.filtered.mp4",
        },
        {
          angle: "LF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340175/340175.filtered.mp4",
        },
        {
          angle: "1BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340041/340041.filtered.mp4",
        },
        {
          angle: "1D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340173/340173.skeleton.mp4",
        },
        {
          angle: "C",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340042/340042.skeleton.mp4",
        },
        {
          angle: "3D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340172/340172.skeleton.mp4",
        },
        {
          angle: "1D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340173/340173.mp4",
        },
        {
          angle: "CF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340141/340141.filtered.mp4",
        },
        {
          angle: "3BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340170/340170.filtered.mp4",
        },
        {
          angle: "1BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340041/340041.mp4",
        },
        {
          angle: "3D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340172/340172.mp4",
        },
        {
          angle: "C",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340042/340042.mp4",
        },
        {
          angle: "RF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340174/340174.mp4",
        },
        {
          angle: "RF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340174/340174.skeleton.mp4",
        },
        {
          angle: "CF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_25_39_Cincinnati_Reds_22_Luke_Maile_Home/340141/340141.skeleton.mp4",
        },
      ]),
      "2fe9db35dc1e793": generateSampleVideos(true, pitch),
      "658dce32f78cece": adaptMockVideos([
        {
          angle: "1D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340173/340173.filtered.mp4",
        },
        {
          angle: "1BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340041/340041.mp4",
        },
        {
          angle: "3BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340170/340170.skeleton.mp4",
        },
        {
          angle: "RF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340174/340174.skeleton.mp4",
        },
        {
          angle: "1D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340173/340173.skeleton.mp4",
        },
        {
          angle: "3BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340170/340170.filtered.mp4",
        },
        {
          angle: "C",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340042/340042.filtered.mp4",
        },
        {
          angle: "CF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340141/340141.skeleton.mp4",
        },
        {
          angle: "3D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340172/340172.skeleton.mp4",
        },
        {
          angle: "RF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340174/340174.mp4",
        },
        {
          angle: "LF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340175/340175.skeleton.mp4",
        },
        {
          angle: "LF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340175/340175.filtered.mp4",
        },
        {
          angle: "LF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340175/340175.mp4",
        },
        {
          angle: "C",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340042/340042.skeleton.mp4",
        },
        {
          angle: "CF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340141/340141.mp4",
        },
        {
          angle: "3BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340170/340170.mp4",
        },
        {
          angle: "1BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340041/340041.skeleton.mp4",
        },
        {
          angle: "CF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340141/340141.filtered.mp4",
        },
        {
          angle: "1D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340173/340173.mp4",
        },
        {
          angle: "C",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340042/340042.mp4",
        },
        {
          angle: "1BP",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340041/340041.filtered.mp4",
        },
        {
          angle: "RF",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340174/340174.filtered.mp4",
        },
        {
          angle: "3D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340172/340172.filtered.mp4",
        },
        {
          angle: "3D",
          videoUrl:
            "https://storage.googleapis.com/bkt-mets-p-cc212-baseball-data-eng-kinatrax-vendor/active/CHC/2023/2023_08_01_19_01_52/Pitching/2023_08_01_21_26_02_Cincinnati_Reds_22_Luke_Maile_Home/340172/340172.mp4",
        },
      ]),
    }[pitch.id] || generateSampleVideos(true, pitch)
  );
};

export const DEFAULT_COMP_VALUES = [
  { label: "MLB 95+", id: 1 },
  { label: "4FB 90%ile", year: "(All)", mph: 93.2, id: 1 },
];

export const PITCHING_DATA_COMP_SWITCH = [
  { label: "MLB 95+", id: 1 },
  { label: "MLB 90+", id: 2 },
  { label: "MLB 85+", id: 3 },
  { label: "MLB 80+", id: 4 },
  { label: "MLB 75+", id: 5 },
  { label: "MLB 70+", id: 6 },
  { label: "MLB 65+", id: 7 },
  { label: "MLB 60+", id: 8 },
];

export const BATTING_DATA_COMP_SWITCH = [
  { label: "MLB 95%ile EV", id: 1 },
  { label: "MLB 90%ile EV", id: 2 },
  { label: "MLB 85%ile EV", id: 3 },
  { label: "MLB 80%ile EV", id: 4 },
  { label: "MLB 75%ile EV", id: 5 },
  { label: "MLB 70%ile EV", id: 6 },
  { label: "MLB 65%ile EV", id: 7 },
  { label: "MLB 60%ile EV", id: 8 },
];

export const SELF_COMP_SWITCH = [
  { label: "Session", id: 1, key: 2 },
  { label: "Season", id: 1, key: 1 },
  { label: "Session Range", id: 1, key: 3 },
];

export const BASE_SELF_COMP_OPTION: SelfCompSelectedValue = {
  type: "Session",
  payload: {
    date: null,
    pitchType: "4FB",
    sup75Perc: false,
  },
};

export const SEASON_CHILDREN = [
  { label: "4FB", id: 0, key: "4FB", value: "4FB" },
  { label: "2FB", id: 1, key: "2FB", value: "2FB" },
  { label: "KN", id: 2, key: "KN", value: "KN" },
  { label: "CH", id: 3, key: "CH", value: "CH" },
  { label: "SL", id: 4, key: "SL", value: "SL" },
  { label: "UN", id: 5, key: "UN", value: "UN" },
  { label: "CB", id: 6, key: "CB", value: "CB" },
  { label: "SF", id: 7, key: "SF", value: "SF" },
  { label: "SB", id: 8, key: "SB", value: "SB" },
  { label: "CT", id: 9, key: "CT", value: "CT" },
  { label: "SW", id: 10, key: "SW", value: "SW" },
];

export const SEASON_MENU = [
  { label: "2021", key: "2021", children: SEASON_CHILDREN },
  { label: "2022", key: "2022", children: SEASON_CHILDREN },
  { label: "2023", key: "2023", children: SEASON_CHILDREN },
  { label: "2024", key: "2024", children: SEASON_CHILDREN },
];

const SEASONS_VALUES = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30, 60];

export const SEASONS_RANGE = SEASONS_VALUES.map(
  (value: number, index: number) => ({
    value: `${value} sessions from`,
    id: index,
    label: `${value} sessions from`,
    key: value,
  })
);

export type KeyframeData = {
  label: string;
  frame: number;
};

export const keyframesData: KeyframeData[] = [
  { label: "MKH", frame: 50 },
  { label: "HS", frame: 140 },
  { label: "FC", frame: 200 },
  { label: "MER", frame: 240 },
  { label: "BR", frame: 360 },
  { label: "MIR", frame: 390 },
  { label: "FT", frame: 370 },
];

export const summaryStatsData: PlayerSummary[] = [
  {
    label: "Overall",
    collapsedLabel: "Overall",
    value: 71,
    currentTarget: 80,
    previousValue: 62.48,
    metric: "overall",
  },
  {
    label: "MSK Strength Index",
    collapsedLabel: "MSK",
    value: 74,
    currentTarget: 80,
    rank: 5,
    percentile: 96,
    previousValue: 75.48,
    metric: "msk",
  },
  {
    label: "Range of Motion",
    collapsedLabel: "ROM",
    value: 20,
    metric: "rom",
  },
  {
    label: "Power Index",
    collapsedLabel: "Power",
    value: 62,
    currentTarget: 80,
    rank: 21,
    percentile: 73,
    previousValue: 54.56,
    metric: "power",
  },
  {
    label: "Body Comp Index",
    collapsedLabel: "Body Comp",
    value: 68,
    currentTarget: 80,
    rank: 81,
    percentile: 81,
    previousValue: 68,
    metric: "body_comp",
  },
  {
    label: "Vision Index",
    collapsedLabel: "Vision",
    value: 71,
    currentTarget: 80,
    rank: 9,
    percentile: 94,
    previousValue: 68.16,
    metric: "vision",
  },
];

export const sequenceByPitch = (pitch: Pitch) => {
  const mainSequence: any[] = SEQUENCE.filter(({ pitchId }) => pitchId === 1);
  const comparingSequence: any[] = SEQUENCE.filter(
    ({ pitchId }) => pitchId === 2
  );

  return (
    { 1: mainSequence, 2: comparingSequence }[pitch.id] || comparingSequence
  );
};

export const selfSequenceByPlayer = (player: Player) =>
  findOrDefaultMockDataByPlayer(player, HISTORY_SEQUENCE);

export const dataCompSequenceByPlayer = (player: Player) =>
  findOrDefaultMockDataByPlayer(player, COMP_DATA_SEQUENCE);

export const PLAYER_DETAIL = {
  name: "Max Scherzer",
  team: "New York Mets",
  number: 21,
  forehand: "R/R",
  height: `6'3"`,
  weight: "200",
  weight_unit: "lbs",
  date: "9/2/93",
  photo:
    "https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/28976.png&w=350&h=254",
};

export const POSITION_PLAYER_EXTRA_METRICS = ["Vision Index"];

export const MOCK_PLAYER_PERFORMANCE_DATA = [
  [
    {
      metric: "msk_hip_er_nd_left",
      value: 0,
    },
    {
      metric: "msk_hip_er_nd_right",
      value: 0,
    },
    {
      metric: "msk_hip_ir_nd_left",
      value: 0,
    },
    {
      metric: "msk_hip_ir_nd_right",
      value: 0,
    },
    {
      metric: "msk_shoulder_er_d_left",
      value: 0,
    },
    {
      metric: "msk_shoulder_er_d_right",
      value: 0,
    },
    {
      metric: "msk_shoulder_ir_d_left",
      value: 0,
    },
    {
      metric: "msk_shoulder_ir_d_right",
      value: 0,
    },
    {
      metric: "msk_hip_abd_nd_left",
      value: 0,
    },
    {
      metric: "msk_hip_abd_nd_right",
      value: 0,
    },
    {
      metric: "msk_hip_add_nd_left",
      value: 0,
    },
    {
      metric: "msk_hip_add_nd_right",
      value: 0,
    },
    {
      metric: "msk_core_rot_d_left",
      value: 0,
    },
    {
      metric: "msk_core_rot_d_right",
      value: 0,
    },
    {
      metric: "msk_grip_90_d_left",
      value: 0,
    },
    {
      metric: "msk_grip_90_d_right",
      value: 0,
    },
    {
      metric: "msk_grip_0_d_left",
      value: 0,
    },
    {
      metric: "msk_grip_0_d_right",
      value: 0,
    },
  ],
  [
    {
      metric: "rom_shoulder_trot_d_left",
      value: 0,
    },
    {
      metric: "rom_shoulder_trot_d_right",
      value: 170,
    },
    {
      metric: "rom_shoulder_er_d_left",
      value: 0,
    },
    {
      metric: "rom_shoulder_er_d_right",
      value: 128,
    },
    {
      metric: "rom_shoulder_ir_d_left",
      value: 0,
    },
    {
      metric: "rom_shoulder_ir_d_right",
      value: 42,
    },
    {
      metric: "rom_shoulder_flex_p_left",
      value: 0,
    },
    {
      metric: "rom_shoulder_flex_p_right",
      value: 0,
    },
    {
      metric: "rom_shoulder_flex_u_left",
      value: 0,
    },
    {
      metric: "rom_shoulder_flex_u_right",
      value: 169,
    },
    {
      metric: "rom_shoulder_add_d_left",
      value: 0,
    },
    {
      metric: "rom_shoulder_add_d_right",
      value: 109,
    },
    {
      metric: "rom_elbow_ext_d_left",
      value: 0,
    },
    {
      metric: "rom_elbow_ext_d_right",
      value: 0,
    },
    {
      metric: "rom_elbow_flex_d_left",
      value: 0,
    },
    {
      metric: "rom_elbow_flex_d_right",
      value: 135,
    },
    {
      metric: "rom_hip_trot_d_left",
      value: 92,
    },
    {
      metric: "rom_hip_trot_d_right",
      value: 85,
    },
    {
      metric: "rom_hip_er_d_left",
      value: 47,
    },
    {
      metric: "rom_hip_er_d_right",
      value: 45,
    },
    {
      metric: "rom_hip_ir_d_left",
      value: 45,
    },
    {
      metric: "rom_hip_ir_d_right",
      value: 40,
    },
    {
      metric: "rom_hip_flex_slr_left",
      value: 0,
    },
    {
      metric: "rom_hip_flex_slr_right",
      value: 0,
    },
    {
      metric: "rom_ankle_flex_d_left",
      value: 0,
    },
    {
      metric: "rom_ankle_flex_d_right",
      value: 0,
    },
  ],
  [
    {
      metric: "pwr_cmj_conc_impulse",
      value: 300.78,
    },
    {
      metric: "pwr_cmj_rsi_modified",
      value: 73.19,
    },
    {
      metric: "pwr_cmj_height",
      value: 23.11,
    },
    {
      metric: "pwr_iso_mtp",
      value: 0,
    },
    {
      metric: "pwr_iso_squat",
      value: 0,
    },
    {
      metric: "pwr_row_test_left",
      value: 0,
    },
    {
      metric: "pwr_row_test_right",
      value: 0,
    },
    {
      metric: "pwr_iso_hamstring_left",
      value: 0,
    },
    {
      metric: "pwr_iso_hamstring_right",
      value: 0,
    },
  ],
  [
    {
      metric: "spd_30y_sprint_time",
      value: 0,
    },
    {
      metric: "spd_30y_sprint_vel",
      value: 0,
    },
    {
      metric: "spd_505_agility_time",
      value: 0,
    },
    {
      metric: "spd_metscast",
      value: 23.8086918945104,
    },
    {
      metric: "spd_power6_time",
      value: 0,
    },
  ],
  [
    {
      metric: "bcmp_height",
      value: 0,
    },
    {
      metric: "bcmp_weight",
      value: 211.9,
    },
    {
      metric: "bcmp_age",
      value: 0,
    },
    {
      metric: "bcmp_wingspan",
      value: 0,
    },
    {
      metric: "bcmp_handsize",
      value: 0,
    },
    {
      metric: "bcmp_bodyfat",
      value: 0,
    },
  ],
  [
    {
      metric: "vis_reaction_time",
      value: 0,
    },
    {
      metric: "vis_timing_err_left",
      value: 0,
    },
    {
      metric: "vis_timing_err_right",
      value: 0,
    },
    {
      metric: "vis_spatial_err_left",
      value: 0,
    },
    {
      metric: "vis_spatial_err_right",
      value: 0,
    },
    {
      metric: "vis_leadlag_timing_err_left",
      value: 0,
    },
    {
      metric: "vis_leadlag_timing_err_right",
      value: 0,
    },
    {
      metric: "vis_combined_precision_left",
      value: 0,
    },
    {
      metric: "vis_combined_precision_right",
      value: 0,
    },
    {
      metric: "vis_reaction_time_left",
      value: 0,
    },
    {
      metric: "vis_reaction_time_right",
      value: 0,
    },
    {
      metric: "vis_horizontal_pursuits_left",
      value: 0,
    },
    {
      metric: "vis_horizontal_pursuits_right",
      value: 0,
    },
    {
      metric: "vis_vertical_pursuits_left",
      value: 0,
    },
    {
      metric: "vis_vertical_pursuits_right",
      value: 0,
    },
    {
      metric: "vis_horizontal_saccades_left",
      value: 0,
    },
    {
      metric: "vis_horizontal_saccades_right",
      value: 0,
    },
    {
      metric: "vis_vertical_saccades_left",
      value: 0,
    },
    {
      metric: "vis_vertical_saccades_right",
      value: 0,
    },
    {
      metric: "vis_fixations_left",
      value: 0,
    },
    {
      metric: "vis_fixations_right",
      value: 0,
    },
  ],
];
export interface PlayerReport {
  id: string;
  date: string;
  type: string;
  description: string;
  pdfUrl?: string;
  fullName?: string;
}
export interface TeamReport {
  id: string;
  date: string;
  type: string;
  teamId: string;
  description: string;
  pdfUrl?: string;
  fullName?: string;
}
export const REPORT_LOCKER_DATA: PlayerReport[] = [
  "MSK",
  "ROM",
  "BMA",
  "CMJ",
].map((type, day) => ({
  id: `${type}-${day}`,
  date: `2023-11-${day + 1}`,
  type,
  pdfUrl: `/player/data/${type}.pdf`,
  description: `A ${type} report`,
}));

export interface PlayerTrends {
  id: string;
  date: string;
  type: string;
  measurement: string;
  delta?: number;
  trend?: string;
  isCurrent?: boolean;
}
export const TRENDS_DATA: PlayerTrends[] = ["MSK", "ROM", "MSK", "ROM"].map(
  (type, day) => ({
    id: `${type}-${day}`,
    date: `2023-11-${day + 1}`,
    type,
    measurement: `A ${type} measurement`,
    delta: day + 3,
    trend: day == 2 ? "" : day % 2 ? "up" : "down",
  })
);

export const DATA_ENTRY_LOG_DATA = [
  {
    id: "a321desa213",
    details: {
      assessmentType: "MSK ROM",
      date: "2023-11-01",
      player: {
        value: "m2a9ff694607f7e8",
        displayValue: "Enderson Asencio",
        position: "LF",
      },
    },
    data: {
      singleExaminer: true,
      examiner: "Joaco",
      temperature: "hot",
      rom_shoulder_add_d_left: "11",
    },
  },
  {
    id: "kl213kl1",
    details: {
      assessmentType: "MSK ROM",
      date: "2023-11-10",
      player: {
        value: "m2612bbfb4109c6c",
        displayValue: "Ryan Coultas",
        position: "RP",
      },
    },
    data: {
      singleExaminer: false,
      examiner: "Juan",
      measurer: "Pablo",
      temperature: "cold",
      rom_knee_ext_left: "2",
    },
  },
  {
    id: "sdf87sdf671",
    details: {
      assessmentType: "Body Composition",
      date: "2023-11-01",
      player: {
        value: "m25043dc71ef00bf",
        displayValue: "Joseph Atwater",
        position: "P",
      },
    },
    data: {
      bcmp_weight: "140",
      bcmp_skin_biceps: "2",
    },
  },
  {
    id: "as8d90hgd",
    details: {
      assessmentType: "Body Composition",
      date: "2023-11-01",
      player: {
        value: "mb886e8d3407a5e1",
        displayValue: "Rafael Castro",
        position: "P",
      },
    },
    data: {
      bcmp_height: "80",
      bcmp_skin_calf: "13",
    },
  },
];

export const MOCK_NORMATIVE_DATA = [
  {
    metricId: "rom_shoulder_trot_d_left",
    data: [
      { level: "MLB", mean: "10.1", stdDev: "0.4" },
      { level: "AAA", mean: "9.1", stdDev: "0.4" },
      { level: "AA", mean: "8.9", stdDev: "0.4" },
      { level: "A+", mean: "8.5", stdDev: "0.4" },
      { level: "A-", mean: "8.4", stdDev: "0.4" },
      { level: "FCL", mean: "8.3", stdDev: "0.4" },
      { level: "DR", mean: "8.3", stdDev: "0.4" },
      { level: "AMA", mean: "7.5", stdDev: "0.4" },
    ],
  },
  {
    metricId: "rom_shoulder_er_d_left",
    data: [
      { level: "MLB", mean: "10.1", stdDev: "0.4" },
      { level: "AAA", mean: "9.1", stdDev: "0.4" },
      { level: "AA", mean: "8.9", stdDev: "0.4" },
      { level: "A+", mean: "8.5", stdDev: "0.4" },
      { level: "A-", mean: "8.4", stdDev: "0.4" },
      { level: "FCL", mean: "8.3", stdDev: "0.4" },
      { level: "DR", mean: "8.3", stdDev: "0.4" },
      { level: "AMA", mean: "7.5", stdDev: "0.4" },
    ],
  },
  {
    metricId: "rom_shoulder_ir_d_dom",
    data: [
      { level: "MLB", mean: "10.1", stdDev: "0.4" },
      { level: "AAA", mean: "9.1", stdDev: "0.4" },
      { level: "AA", mean: "8.9", stdDev: "0.4" },
      { level: "A+", mean: "8.5", stdDev: "0.4" },
      { level: "A-", mean: "8.4", stdDev: "0.4" },
      { level: "FCL", mean: "8.3", stdDev: "0.4" },
      { level: "DR", mean: "8.3", stdDev: "0.4" },
      { level: "AMA", mean: "7.5", stdDev: "0.4" },
    ],
  },
];

const generateMockPlayerDashboardMeans = (
  meanGenerator: (metric: any) => number
) =>
  flatten(MOCK_PLAYER_PERFORMANCE_DATA).reduce(
    (acc, it) => ({ ...acc, [it.metric]: meanGenerator(it) }),
    {}
  );

export const MOCK_PLAYER_DASHBOARD_PLAYER_MEANS =
  generateMockPlayerDashboardMeans((it) => it.metric.length * 2);

export const MOCK_PLAYER_DASHBOARD_TEAM_MEANS =
  generateMockPlayerDashboardMeans((it) => it.metric.length * 4);

export const OBU_PROGRESSION_METRICS = [
  "obu_ankle_rocking_eversion_hold",
  "obu_ankle_rocking_inversion_hold",
  "obu_sl_hinge_foot_position",
  "obu_deep_squat_stability",
  "obu_hip_ir_hold",
  "obu_pelvic_tilt_posture",
  "obu_pelvic_tilt_stability",
  "obu_pelvic_rotation_hold",
  "obu_pelvic_rotation_coordination",
  "obu_trunk_rotation_hold",
  "obu_shoulder_elbowup",
  "obu_shoulder_add_posture",
];

export interface PlayerSession {
  date: string;
  count: number;
  sources: SourcePitch[];
}

export interface PlayerInsight {
  type: string;
  date: string;
  enteredBy: string;
  text: string;
  nutrition_supplements?: string;
  oscontact_player_status?: string;
}

export interface PlayerTransfer extends PlayerInsight {}

export interface ComparingData {
  id: number;
  label: string;
  key: string;
}

export const DATACOMP_VALUES: ComparingData[] = [
  {
    label: "Group Average (Pitchers)",
    id: 1,
    key: "groupAveragePitchers",
  },
  {
    label: "Group Average (Position Players)",
    id: 2,
    key: "groupAveragePositionPlayers",
  },
];

export const SELFCOMP_VALUES: ComparingData[] = [
  {
    label: "Player Average",
    id: 1,
    key: "playerAverage",
  },
];

export const SWING_DATA = {
  pitch_id: "s38a35d535797f85",
  game_id: "se6f39a75c482208",
  play_id: null,
  pitch_code: "B",
  pitch_type: "4FB",
  pitch_of_game: 242,
  pitch_of_pitcher: 9,
  pitch_of_pa: 3,
  pre_balls: 0,
  pre_strikes: 2,
  post_balls: 1,
  post_strikes: 2,
  pre_outs: 1,
  post_outs: 1,
  batter_id: "m9d2a09880e1a940",
  pitcher_id: null,
  is_ball: true,
  is_strike: false,
  is_swing: false,
  is_miss: false,
  is_foul: false,
  is_bunt: null,
  is_in_play: false,
  is_pitchout: false,
  is_last_pitch: false,
  bigquery_updated_at: "2022-01-19T14:54:26.302Z",
  created_at: "2024-03-11T01:04:39.704Z",
  updated_at: "2024-03-11T01:04:39.704Z",
  rel_speed: 96,
  horz_rel_angle: 66,
  vert_rel_angle: 77,
  rel_height: 88,
  rel_side: 99,
  extension: 123,
  horz_break: 234,
  vert_break_induced: 345,
  spin_rate: 6,
  spin_axis: 7,
  spin_vector_x: null,
  spin_vector_y: null,
  spin_vector_z: null,
  tilt: null,
  plate_side: -1.6211391750981812,
  plate_height: 4.508687461104828,
  bip_trajectory: "popup",
  time_to_plate: null,
  hit_speed: 99,
  hit_horz_angle: 10,
  hit_vert_angle: 12,
  hit_distance: 222,
  hit_bearing: 12,
  cvr: null,
  game_level: "MILB",
  source: "synergy",
  vert_break: null,
  hang_time: 12,
  out_probability: null,
  hit_spin_rate: null,
  game_date: "2016-09-05T00:00:00.000Z",
  game_type: null,
  batter_team_id: null,
  pitcher_team_id: null,
  pre_runner_1b_id: "mbbc965fb5624d7a",
  pre_runner_2b_id: null,
  pre_runner_3b_id: null,
  first_fielder_id: null,
  inning: "9",
  is_bottom: false,
  batter_side: "L",
  pitcher_hand: null,
  rel_time: 234,
  session_id: null,
  post_home_score: 2,
  post_away_score: 6,
  pitch_type_group: "FB",
  expected_strike: null,
  catcher_id: null,
  stuff: null,
  level_id: null,
  prv: null,
  spin_efficiency: null,
  vert_approach_angle: 45,
  is_in_dirt: null,
  is_foul_tip: null,
  activity_type: "game",
  batting_xrvcon: 123,
  pitching_xrvcon: 456,
};
