import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_COMP_VALUES,
  Level,
  Org,
  Pitch,
  Player,
  Position,
  DATACOMP_VALUES,
  SELFCOMP_VALUES,
  ComparingData,
  BASE_SELF_COMP_OPTION,
} from "../services/mockData";
import { PlayerDashboardDataComp } from "../components/PlayerDashboard/PlayerDashboardDataComp";
import { ExpandedMetricsDataIndexing } from "../components/PlayerDashboard/ExpandedMetricsDataIndexing";
import { PitchIndexing } from "../components/PlayerDashboard/PitchIndexing";
import { DEFAULT_PERIOD, Period } from "../components/common/PeriodSelector";
import { TeamPlayerPosition } from "../components/TeamDashboard/TeamDataSelector/PositionSelector";
import { TeamMetricsMode } from "../components/TeamDashboard/TeamDataSelector/TeamMetricsModeSelector";
import { SelfCompSelectedValue } from "../components/common/SelfMenu";
import { localStorageRead, persistOnLocalStorage } from "./helpers";
import { MotionType } from "../components/common/MotionType";
import {
  WorkloadMetricDefinition,
  workloadDefaultKeyMetrics,
} from "../components/PlayerDashboard/WorkloadWidget/workloadMetricsDefinition";
import { AppStoreApp } from "../components/AppStore/appStoreApi.service";
export interface SelectedMovement {
  id: number;
  level: Level;
  player: Player | null;
  date: string;
  org: Org | null;
  trial: Pitch | null;
  position: Position | null;
}

export type MetricGroupKind =
  | "workload"
  | "bodyComp"
  | "vision"
  | "obu"
  | "power"
  | "msk"
  | "rom";

export type PlayerDashSectionSettings = {
  key: MetricGroupKind;
  enabled: boolean;
};

export type PlayerDashPositionSettings = PlayerDashSectionSettings[];

interface PerPositionSettings<T> {
  pitcher: T;
  positionPlayer: T;
}

export type PlayerDashSettings =
  PerPositionSettings<PlayerDashPositionSettings>;

export type PlayerPositionKind = "pitcher" | "positionPlayer";
export type SelectedWorkloadKeyMetrics = PerPositionSettings<string[]>;

const storageKeys = {
  PLAYER_DASH_SETTINGS: "player-dashboard-settings-v4",
  WORKLOAD_KEY_METRICS: "workload-key-metrics",
  APPS_PINNED: "apps-pinned-v2",
};

const playerDashDefaults: PlayerDashPositionSettings = [
  { key: "workload", enabled: true },
  { key: "msk", enabled: true },
  { key: "rom", enabled: true },
  { key: "power", enabled: true },
  { key: "bodyComp", enabled: true },
  { key: "vision", enabled: true },
  { key: "obu", enabled: true },
];

const playerDashSettingsDefault: PlayerDashSettings = {
  pitcher: playerDashDefaults,
  positionPlayer: playerDashDefaults,
};
const queryParams = new URLSearchParams(window.location.search);
const queryMotionType = queryParams.get("motionType");
const initialSelectedMotionType =
  queryMotionType === "Batting" ? MotionType.Batting : MotionType.Pitching;

export interface MovementState {
  movement: any; // SelectedMovement
  comparingMovement: any; // SelectedMovement
  extraMovements: SelectedMovement[];
  isDataCompare: boolean;
  isSelfCompare: boolean;
  isSecondarySelfCompare: boolean;
  dataCompValue: any | undefined;
  selfCompValue: any | undefined;
  secondarySelfCompValue: any | undefined;
  currentMetric: string;
  firstFrameValue: number;
  lastFrameValue: number;
  videoDurationValue: number;
  currentFrameValue: number;
  maxValueScrubb: number;
  selectedMetric: any;
  timeSeriesMetrics: any[];
  playerSelectedDataComp: PlayerDashboardDataComp;
  periodSelectorValue: Period;
  dataIndexing: ExpandedMetricsDataIndexing;
  includeOppositeSide: false;
  isNonDominant: false;
  pitchIndexing: PitchIndexing;
  teamPlayerPosition: TeamPlayerPosition;
  dataCompValues: ComparingData;
  selfCompValues: ComparingData;
  measureExpandedViewTimePeriod: Period;
  teamMetricsMode: TeamMetricsMode;
  entryType: string | null;
  assessmentType: string | null;
  showNewData: boolean;
  selfCompOptions: SelfCompSelectedValue;
  secondarySelfCompOptions: SelfCompSelectedValue;
  playerDashSettings: PlayerDashSettings;
  workloadKeyMetrics: SelectedWorkloadKeyMetrics;
  selectedMotionType: MotionType;
  workloadOutingDate: string | null;
  syncReferenceKeyFrame: string;
  appsPinnedIds: string[];
  pitchingKinatraxReportScrubberLock: boolean;
  skeleModalOpen: boolean;
}

const initialState: MovementState = {
  movement: {},
  comparingMovement: {},
  extraMovements: [],
  isDataCompare: true,
  isSelfCompare: false,
  isSecondarySelfCompare: false,
  dataCompValue: undefined,
  selfCompValue: DEFAULT_COMP_VALUES[1],
  secondarySelfCompValue: DEFAULT_COMP_VALUES[1],
  currentMetric: "",
  firstFrameValue: 0,
  lastFrameValue: 0,
  videoDurationValue: 0,
  currentFrameValue: 0,
  maxValueScrubb: 0,
  selectedMetric: {},
  timeSeriesMetrics: [],
  playerSelectedDataComp: PlayerDashboardDataComp.TeamAvg,
  periodSelectorValue: DEFAULT_PERIOD,
  dataIndexing: ExpandedMetricsDataIndexing.Date,
  includeOppositeSide: false,
  isNonDominant: false,
  pitchIndexing: PitchIndexing.AllPitches,
  teamPlayerPosition: TeamPlayerPosition.Pitcher,
  dataCompValues: DATACOMP_VALUES[0],
  selfCompValues: SELFCOMP_VALUES[0],
  measureExpandedViewTimePeriod: DEFAULT_PERIOD,
  teamMetricsMode: TeamMetricsMode.PlayerData,
  entryType: null,
  assessmentType: null,
  showNewData: false,
  selfCompOptions: BASE_SELF_COMP_OPTION,
  secondarySelfCompOptions: BASE_SELF_COMP_OPTION,
  playerDashSettings:
    localStorageRead(storageKeys.PLAYER_DASH_SETTINGS) ??
    playerDashSettingsDefault,
  workloadKeyMetrics:
    localStorageRead(storageKeys.WORKLOAD_KEY_METRICS) ??
    workloadDefaultKeyMetrics,
  selectedMotionType: initialSelectedMotionType,
  syncReferenceKeyFrame:
    initialSelectedMotionType === MotionType.Batting ? "BC" : "BR",
  workloadOutingDate: null,
  appsPinnedIds: localStorageRead(storageKeys.APPS_PINNED) ?? [
    "pitching_kinatrax_biomechanics",
    "3d_viewer",
    "data_collection",
    "workload_builder",
  ],
  pitchingKinatraxReportScrubberLock: false,
  skeleModalOpen: false,
};

const movementSlice = createSlice({
  name: "movement",
  initialState,
  reducers: {
    setMovements: (state, action) => {
      return { ...state, movement: action.payload };
    },
    setComparingMovement: (state, action) => {
      return { ...state, comparingMovement: action.payload };
    },
    setExtraMovements: (state, action) => {
      return { ...state, extraMovements: action.payload };
    },
    setIsDataCompare: (state, action) => {
      return { ...state, isDataCompare: action.payload };
    },
    setIsSelfCompare: (state, action) => {
      return { ...state, isSelfCompare: action.payload };
    },
    setIsSecondarySelfCompare: (state, action) => {
      return { ...state, isSecondarySelfCompare: action.payload };
    },
    setCurrentMetric: (state, action) => {
      return { ...state, currentMetric: action.payload };
    },
    setFirstFrameValue: (state, action) => {
      return { ...state, firstFrameValue: action.payload };
    },
    setLastFrameValue: (state, action) => {
      return { ...state, lastFrameValue: action.payload };
    },
    setVideoDurationValue: (state, action) => {
      return { ...state, videoDurationValue: action.payload };
    },
    setCurrentFrameValue: (state, action) => {
      return { ...state, currentFrameValue: action.payload };
    },
    setDataCompValue: (state, action) => {
      return { ...state, dataCompValue: action.payload };
    },
    setSelfCompValue: (state, action) => {
      return { ...state, selfCompValue: action.payload };
    },
    setSecondarySelfCompValue: (state, action) => {
      return { ...state, secondarySelfCompValue: action.payload };
    },
    setMaxValueScrubb: (state, action) => {
      return { ...state, maxValueScrubb: action.payload };
    },
    setSelectedMetricValue: (state, action) => {
      return { ...state, selectedMetric: action.payload };
    },
    setTimeSeriesMetrics: (state, action) => {
      return { ...state, timeSeriesMetrics: action.payload };
    },
    setPlayerSelectedDataComp: (state, action) => {
      return { ...state, playerSelectedDataComp: action.payload };
    },
    setPeriodSelectorValue: (state, action) => {
      return { ...state, periodSelectorValue: action.payload };
    },
    setDataIndexingValue: (state, action) => {
      return { ...state, dataIndexing: action.payload };
    },
    setIncludeOppositeSide: (state, action) => {
      return { ...state, includeOppositeSide: action.payload };
    },
    setIsNonDominant: (state, action) => {
      return { ...state, isNonDominant: action.payload };
    },
    setPitchIndexing: (state, action) => {
      return { ...state, pitchIndexing: action.payload };
    },
    setEntryType: (state, action) => {
      return { ...state, entryType: action.payload };
    },
    setAssessmentType: (state, action) => {
      return { ...state, assessmentType: action.payload };
    },
    setSelectedMotionType: (state, action) => {
      return { ...state, selectedMotionType: action.payload };
    },
    setSelfCompOptions: (
      state,
      action: PayloadAction<SelfCompSelectedValue>
    ) => {
      return { ...state, selfCompOptions: action.payload };
    },
    setSecondarySelfCompOptions: (
      state,
      action: PayloadAction<SelfCompSelectedValue>
    ) => {
      return { ...state, secondarySelfCompOptions: action.payload };
    },
    setTeamPlayerPosition: (
      state,
      action: PayloadAction<TeamPlayerPosition>
    ) => {
      state.teamPlayerPosition = action.payload;
    },
    setDataCompValues: (state, action: PayloadAction<ComparingData>) => {
      state.dataCompValues = action.payload;
    },
    setSelfCompValues: (state, action: PayloadAction<ComparingData>) => {
      state.selfCompValues = action.payload;
    },
    setMeasureExpandedViewTimePeriod: (
      state,
      action: PayloadAction<Period>
    ) => {
      state.measureExpandedViewTimePeriod = action.payload;
    },
    setTeamMetricsMode: (state, action: PayloadAction<TeamMetricsMode>) => {
      state.teamMetricsMode = action.payload;
    },
    setShowNewData: (state, action: PayloadAction<boolean>) => {
      state.showNewData = action.payload;
    },
    setSyncReferenceKeyFrame: (state, action: PayloadAction<string>) => {
      state.syncReferenceKeyFrame = action.payload;
    },
    setPlayerDashSettings: (
      state,
      action: PayloadAction<{
        position: PlayerPositionKind;
        settings: PlayerDashPositionSettings;
      }>
    ) => {
      const { position, settings } = action.payload;
      state.playerDashSettings[position] = settings;
    },
    setWorkloadKeyMetrics: (
      state,
      action: PayloadAction<{
        position: PlayerPositionKind;
        metrics: WorkloadMetricDefinition[];
      }>
    ) => {
      const { position, metrics } = action.payload;
      state.workloadKeyMetrics[position] = metrics.map((it) => it.key);
    },
    resetWorkloadKeyMetrics: (
      state,
      action: PayloadAction<PlayerPositionKind>
    ) => {
      const position = action.payload;
      state.workloadKeyMetrics[position] = workloadDefaultKeyMetrics[position];
    },
    setAppsPinned: (state, action: PayloadAction<AppStoreApp[]>) => {
      state.appsPinnedIds = action.payload.map((it) => it.id);
    },
    toggleAppPin: (state, action: PayloadAction<AppStoreApp>) => {
      const app = action.payload;
      if (state.appsPinnedIds.includes(app.id)) {
        state.appsPinnedIds = state.appsPinnedIds.filter((it) => it !== app.id);
      } else {
        state.appsPinnedIds.push(app.id);
      }
    },
    togglePlayerDashSectionSetting: (
      state,
      action: PayloadAction<{
        position: PlayerPositionKind;
        sectionSettings: PlayerDashSectionSettings;
      }>
    ) => {
      const {
        position,
        sectionSettings: { key },
      } = action.payload;
      const index = state.playerDashSettings[position].findIndex(
        (setting) => setting.key === key
      );
      if (index !== -1) {
        state.playerDashSettings[position][index].enabled =
          !state.playerDashSettings[position][index].enabled;
      }
    },
    resetPlayerDashSettings: (
      state,
      action: PayloadAction<PlayerPositionKind>
    ) => {
      const position = action.payload;
      state.playerDashSettings[position] = playerDashSettingsDefault[position];
    },
    setWorkloadOutingDate: (state, action: PayloadAction<string | null>) => {
      state.workloadOutingDate = action.payload;
    },
    setPitchingKinatraxReportScrubberLock: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.pitchingKinatraxReportScrubberLock = action.payload;
    },
    setSkeleModalOpen: (state, action: PayloadAction<boolean>) => {
      state.skeleModalOpen = action.payload;
    },
  },
});

export const {
  setMovements,
  setComparingMovement,
  setExtraMovements,
  setIsDataCompare,
  setIsSelfCompare,
  setIsSecondarySelfCompare,
  setCurrentMetric,
  setFirstFrameValue,
  setLastFrameValue,
  setMaxValueScrubb,
  setVideoDurationValue,
  setCurrentFrameValue,
  setDataCompValue,
  setSelfCompValue,
  setSecondarySelfCompValue,
  setSelectedMetricValue,
  setTimeSeriesMetrics,
  setPlayerSelectedDataComp,
  setPeriodSelectorValue,
  setDataIndexingValue,
  setIncludeOppositeSide,
  setIsNonDominant,
  setPitchIndexing,
  setTeamPlayerPosition,
  setDataCompValues,
  setSelfCompValues,
  setMeasureExpandedViewTimePeriod,
  setTeamMetricsMode,
  setEntryType,
  setAssessmentType,
  setShowNewData,
  setSelfCompOptions,
  setSecondarySelfCompOptions,
  setPlayerDashSettings,
  setWorkloadKeyMetrics,
  resetWorkloadKeyMetrics,
  togglePlayerDashSectionSetting,
  resetPlayerDashSettings,
  setSelectedMotionType,
  setWorkloadOutingDate,
  setSyncReferenceKeyFrame,
  toggleAppPin,
  setPitchingKinatraxReportScrubberLock,
  setSkeleModalOpen,
} = movementSlice.actions;

export default movementSlice.reducer;

persistOnLocalStorage(
  storageKeys.PLAYER_DASH_SETTINGS,
  (state) => state.movement.playerDashSettings
);

persistOnLocalStorage(
  storageKeys.WORKLOAD_KEY_METRICS,
  (state) => state.movement.workloadKeyMetrics
);

persistOnLocalStorage(
  storageKeys.APPS_PINNED,
  (state) => state.movement.appsPinnedIds
);
