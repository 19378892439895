import React, { useEffect, useMemo, useState } from "react";
import { Button, Divider, Typography, Radio, Checkbox } from "antd";
import { Chip, Grid, Popover, Stack, useTheme } from "@mui/material";
import { QUALIFIERS } from "./mockFilters";
import { Unless } from "react-if";
import { findIndex, isEmpty, isNil, sortBy } from "lodash";
import { useSelector } from "react-redux";
import { selectMovement, selectSelfCompOptions } from "../../redux/selectors";
import { useFetchSelfCompPitchTypesQuery } from "../../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { usePerformanceAppDispatch } from "../../redux/hooks";
import { setSelfCompOptions } from "../../redux/movementSlice";
import { pitchTypesByPriority } from "../../utils/metrics";
import { Player } from "../../services/mockData";

const { Text, Title } = Typography;

export type PitchTypesSelectorValues = {
  pitchType?: string;
  sup75Perc?: boolean;
};
type PitchTypesSelectorProps =
  | {
      player?: Player;
      values?: PitchTypesSelectorValues;
      multi?: false;
      error?: string;
      onChange?: (key: string, newValue: string | boolean) => void;
    }
  | {
      player?: Player;
      values?: string[];
      multi: true;
      error?: string;
      onChange: (newValues: string[]) => void;
    };
const PitchTypesSelector = ({
  player: playerProp,
  values,
  onChange,
  multi: multi = false,
  error,
}: PitchTypesSelectorProps) => {
  const theme = useTheme();
  const dispatch = usePerformanceAppDispatch();
  const { player: storePlayer } = useSelector(selectMovement);
  const player = !isNil(playerProp?.id) ? playerProp : storePlayer;
  const { data: allPitchTypes, isFetching } = useFetchSelfCompPitchTypesQuery(
    isNil(player) ? skipToken : player?.id
  );
  const { payload } = useSelector(selectSelfCompOptions);
  // @ts-expect-error
  const { pitchType, sup75Perc } = multi
    ? { pitchType: undefined, sup75Perc: false }
    : !isNil(values)
    ? values
    : payload;
  const [anchorEl, setAnchorEl] = useState(null);
  const mappedQualifier = sup75Perc ? "sup75Perc" : "all";
  const open = anchorEl !== null;

  const pitchTypes = useMemo(() => {
    const availablePitchTypes = sortBy(allPitchTypes, (it) => {
      const index = findIndex(
        pitchTypesByPriority,
        (element) => element == it.pitch_type
      );
      return index === -1 ? 99 : index;
    });

    return availablePitchTypes.map((it) => ({
      label: it.pitch_type,
      value: it.pitch_type,
      checked: it.pitch_type === pitchType,
    }));
  }, [allPitchTypes, pitchTypesByPriority, pitchType, sup75Perc]);

  useEffect(() => {
    if (multi && !isEmpty(pitchTypes) && isNil(values)) {
      // @ts-expect-error in multiple mode, onChange is required
      onChange([pitchTypes[0].value]);
    }
  }, [multi, pitchTypes, values]);

  const qualifiers = QUALIFIERS.map((it) => ({
    ...it,
    checked: mappedQualifier === it.value,
  }));

  const handleCancel = () => {
    setAnchorEl(null);
  };

  const updateSelfCompOptions = (newValue: {
    [key: string]: string | boolean;
  }) => {
    if (!isNil(onChange)) {
      const keys = Object.keys(newValue);

      keys.forEach((key: any) => {
        onChange(key, newValue[key]);
      });
    } else {
      dispatch(
        setSelfCompOptions({
          type: "Session",
          payload: { ...payload, ...newValue },
        })
      );
    }
  };

  const updatePitchType = (pitchType: string) => {
    updateSelfCompOptions({ pitchType });
  };

  const updateQualifiers = (sup75Perc: boolean) => {
    updateSelfCompOptions({ sup75Perc });
  };

  const clearAll = () => {
    if (multi) {
      // @ts-expect-error in multiple mode, onChange is required
      onChange([]);
    } else {
      updateSelfCompOptions({
        pitchType: pitchTypes[0].value,
        sup75Perc: false,
      });
    }
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      item
      xs={12}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
          loading={isFetching}
          danger={!isNil(error)}
        >
          {multi ? "Select" : "Pitch Type"}
        </Button>

        {multi ? (
          // @ts-expect-error in multiple mode, values is an array
          values?.map((it) => (
            <Chip variant="outlined" size="small" key={it} label={it} />
          ))
        ) : (
          <Unless condition={isNil(pitchType)}>
            <Chip variant="outlined" size="small" label={pitchType} />
          </Unless>
        )}

        <Unless condition={!sup75Perc}>
          <Chip
            size="small"
            label={!sup75Perc ? "All" : ">75%"}
            onDelete={() => updateQualifiers(false)}
          />
        </Unless>
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ zIndex: theme.zIndex.modal + 1 }}
      >
        <Stack spacing={1} m={2} px={2}>
          <Title level={4}>Filters</Title>
          <Divider style={{ marginTop: 0 }} />

          <Text>Pitch Types</Text>
          {multi ? (
            <Checkbox.Group
              options={pitchTypes}
              // @ts-expect-error in multiple mode, values is an array
              value={values}
              // @ts-expect-error in multiple mode, onChange is required
              onChange={(values) => onChange(values)}
            ></Checkbox.Group>
          ) : (
            pitchTypes.map((pitchType: any) => (
              <Radio
                key={pitchType.value}
                value={pitchType.value}
                onChange={(event) => {
                  updatePitchType(event.target.value);
                }}
                checked={pitchType.checked}
              >
                {pitchType.label}
              </Radio>
            ))
          )}

          <Unless condition={multi}>
            <Text>Qualifiers</Text>
            {qualifiers.map((qualifier: any) => (
              <Radio
                key={qualifier.value}
                value={qualifier.value}
                onChange={(event) => {
                  const mappedValue = event.target.value === "sup75Perc";
                  updateQualifiers(mappedValue);
                }}
                checked={qualifier.checked}
              >
                {qualifier.label}
              </Radio>
            ))}
          </Unless>

          <Button type="link" onClick={clearAll}>
            Clear all filters
          </Button>
        </Stack>
      </Popover>
    </Grid>
  );
};

export default PitchTypesSelector;
