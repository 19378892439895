import { DateTime, Interval } from "luxon";
import palette from "../styles/palette";
import { LOAD_STATUS } from "../constants";
import dayjs from "dayjs";

/**
 * Forces a numeric response between a given min and max
 * @param {Number} min - Absolute minimum value that we can return
 * @param {Number} max - Absolute maximum value that we can return
 * @param {Number} value - A value to test against min and max
 * @returns value, if it exists between min and max, otherwise min if value is lower, max if it is higher
 * Example, I want to force a percentage between 0-100, and I pass in a value of 4000 (return would be 100)
 */
const clamp = (min, max, value) => {
  return Math.min(Math.max(value, min), max);
};

/**
 * percentToHexString takes a percent and returns the two-digit hex string
 * representation of percent * 255
 * In other words, this function answers the prompt "give me hex percent of 255"
 * where the answer is always a two-digit hex string
 * Input values above 1.0 or below 0.0 will be rounded to 1.0 and 0.0, respectively
 * @param {*} percent a decimal value between 0.0 and 1.0
 * @returns a string of two hex digits
 */
const percentToHexString = (percent) => {
  const clampedPercent = clamp(0.0, 1.0, percent);

  const convertedToHex = Math.round(clampedPercent * 255);
  return convertedToHex.toString(16).toUpperCase().padStart(2, "0");
};

/**
 * Gets the high-low colors for cell coloration
 * Green-Red by default, Blue-Red for colorblind-friendliness
 * @param {bool} isColorblindFriendly - if true, we use red-blue instead of red-green
 * @returns {object} with properties `high` and `low` set to appropriate hex values
 */
const getRankColors = (isColorblindFriendly) => {
  let high = palette.green;
  let low = palette.red;
  if (isColorblindFriendly) {
    low = palette.cbHigh;
    high = palette.red;
  }
  return { high, low };
};

/**
 * Determines the coloration of a stat based on the statistical average
 * and standard deviation. The colors range from palette.green to
 * palette.red.
 * @param {float} value - a particular stat value
 * @param {float} sd - the standard deviation of this stat across some population
 * @param {float} avg - the average value of this stat across some population
 * @param {bool} inverse - if true, lower numbers get the high coloration
 * @param {string} high - hex value of high coloration
 * @param {string} low - hex value of low coloration
 * @returns a hex string identifying the appropriate color or null in the
 * case of an exact midpoint value
 */
const statColorRank = (
  value,
  sd,
  avg,
  inverse = false,
  high = palette.green,
  low = palette.red
) => {
  // Find how many Standard Deviations our value is from the mean
  let normalized = (value - avg) / sd;
  // For coloring purposes, we adjust to no more than 3 sd from mean
  normalized = clamp(-3, 3, normalized);
  // Reset the scale of the difference to be from 0 (red) - 1 (green)
  normalized = normalized / 6 + 0.5;
  // Non numeric values get neutral (mid-point) coloring
  normalized = isNaN(normalized) ? 0.5 : normalized;

  // exact midpoint gets no coloration
  if (normalized === 0.5) {
    return null;
  }
  // set an opacity depending on how big the number is
  const opacity = (normalized - 0.5) / 0.5;
  // set color based on whether normalized value is above 0.5
  // and whether directionality is inversed
  const color =
    (opacity >= 0 && !inverse) || (opacity <= 0 && inverse) ? high : low;

  return `${color}${percentToHexString(Math.abs(opacity))}`;
};

/**
 * Courtesy of https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
 * Turns any title into a friendly slug for use as an anchor link / element ID
 * @param {String} string - A string which may contain whitespace, or special characters or otherwise url-unfriendly characters
 * @returns - A sanitized version of that string with only alphabenumeric (but can't begin with numerals) characters and dashes
 */
const slugifyTitle = (string) => {
  if (!string) return null;
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  let slug = string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text

  // ensure that the string doesn't begin with numerals
  while (!isNaN(slug.charAt(0)) || slug.charAt(0) === "-") {
    slug = slug.substring(1);
  }

  return slug;
};

const formatBytes = (bytes, decimals) => {
  if (bytes == 0) return "0 Bytes";
  const k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * A formatter appropriate for BA, OPS, SLG, OPS, etc. For numbers above 1, you will get the whole number and three digits,
 * but for values between 0 and 1, you'll simply get a decimal point and three digits (no leading 0)
 * @param {Number} value - A stat value provided in numeric format
 * @returns If the value is below 1, this returns a ".XXX" string, otherwise it will return "X.XXX"
 */
const threeDigitPercentiles = (value) => {
  if (isNaN(value)) {
    return "--";
  }
  let number = Number(value).toFixed(3);
  if (number < 1) {
    return String(number).substring(1); // trim the leading 0
  }
  return String(number);
};

/**
 * A formatter appropriate for BB%, K%, Oppo%, Pull%, etc. Will read out as
 * @param {Number} value - A stat value between 0 and 1, representing a percentage
 * @returns A number between 0 and 100 (with up to 1 decimal digit), rendered with a percentage sign
 */
const percentage = (value) => {
  // null, undefined, or not a number get --
  if (value == null || isNaN(value)) {
    return "--";
  }

  //Ensures a percentage returns a number in the XX.X% format, ex. 10.0% instead of 10%
  let number = (value * 100).toFixed(1);
  return String(number) + "%";
};

const percentageToDecimal = (value) => value / 100.0;
const decimalToPercentageValue = (value) => (value * 100).toFixed(1);

const percentageNoZeros = (value) => {
  // 0, null, undefined or not a number get --
  if (!value || isNaN(value)) {
    return "--";
  }
  return percentage(value);
};

/**
 * A number formatter that places commons as separators (e.g. 10000 --> 10,000)
 * @param {Number} x - A real number (preferrably larger than 999 in absolute value)
 */
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * A number formatter that places 3 digits after a decimal
 * @param {Number} number - A real number
 */

const threeDigitDecimals = (number) => {
  if (isNaN(number)) {
    return "--";
  }
  return Number(number).toFixed(3);
};

/**
 * A number formatter that places 2 digits after a decimal
 * @param {Number} number - A real number
 */
const twoDigitDecimals = (number) => {
  if (isNaN(number)) {
    return "--";
  }
  return Number(number).toFixed(2);
};

/**
 * A number formatter that places 1 digits after a decimal
 * @param {Number} number - A real number
 */
const oneDigitDecimal = (number) => {
  if (isNaN(number)) {
    return "--";
  }
  return Number(number).toFixed(1);
};

/**
 * A number formatter that rounds to the nearest whole number
 * @param {Number} number - A real number
 */
const wholeNumber = (number) => {
  if (isNaN(number)) {
    return "--";
  }
  return Number(number).toFixed(0);
};

const wholeNumberNearestTen = (number) => {
  if (isNaN(number)) {
    return "--";
  }
  return Math.round(number / 10) * 10;
};

/**
 * A date formatter that ensures leading zeroes. Add your own formats!
 * @param {Date} date - A JavaScript date object
 * @param {String} format - The format you wish to return. As of now, formats must be added manually with an else if statement
 */
const formatDateToString = (date, format) => {
  if (!(date instanceof Date)) {
    throw new Error("Please pass a date object");
  }
  var dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  var MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  var yyyy = date.getFullYear();
  var yy = yyyy.toString().slice(2);

  if (format === "yyyy-mm-dd") {
    return `${yyyy}-${MM}-${dd}`;
  } else {
    return `${MM}/${dd}/${yy}`;
  }
};

/**
 * A date formatter that takes an iso8601 string and returns "MM/dd/yyyy"
 * @param {*} isoDate
 * @returns
 */
const formatDateFromIso = (isoDate) =>
  DateTime.fromISO(isoDate, { zone: "utc" }).toFormat("MM/dd/yyyy");

/**
 * A function that tests for array equality (by value).
 * @param {Array} a
 * @param {Array} b
 * @returns true if the arrays are equal. Returns false if they are not equal
 */
const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const encode = (arrayOrObject) => {
  return arrayOrObject ? btoa(JSON.stringify(arrayOrObject)) : null;
};
const decode = (encodedString) => {
  return encodedString ? JSON.parse(atob(encodedString)) : null;
};

/***
 * Ranges to help with formatting number with appropriate suffix
 */

const ranges = [
  { divider: 1e12, suffix: "T" }, // trillions
  { divider: 1e9, suffix: "B" }, // billions
  { divider: 1e6, suffix: "M" }, // millions
  { divider: 1e3, suffix: "K" }, // thousands
];
/**
 * A formatter to display a number with monetary format
 * @param {Number} value - a number in numeric format
 * @returns this returns a string in the format of "0.0X" with X representing the
 * number place suffix
 * ex: 2900000 ->  2.9M
 * ex: 2900 -> 2.9k
 */

const formatNumWithSuffix = (num) => {
  if (!num) return "--"; // protects against null values
  for (let idx = 0; idx < ranges.length; idx++) {
    if (num >= ranges[idx].divider) {
      return (
        (num / ranges[idx].divider).toFixed(1).toString() + ranges[idx].suffix
      );
    }
  }
  return num.toString();
};

/**
 * A formatter to add suffixes to numbers
 * @param {Number} i - A valid number
 * @returns this returns a string that attaches the number's ordinal
 * suffix after the number
 * e.g. 1 -> 1st
 * e.g. 22 -> 22nd
 */

function ordinalSuffix(i) {
  if (!isNaN(i) && !!i) {
    let j = i % 10;
    let k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    } else if (j === 2 && k !== 12) {
      return i + "nd";
    } else if (j === 3 && k !== 13) {
      return i + "rd";
    } else {
      return i + "th";
    }
  } else return "--";
}

/**
 * A formatter to strip spaces from strings
 */

function stripSpaces(str) {
  return str.toString().replace(/\s+/g, "");
}
/**
 * From the glossary: we display prv and stuff "scaled by league average number of total pitches per 600 plate appearances"
 * Our database stores raw (unadjusted) values that are useful in certain cases, but in some cases (e.g. Pitch Arsenal)
 * we display scaled values for readability
 *
 * Further context about these specific numbers:
 * - negative: these metrics are related to run value, and better defense
 *   means negative runs (negative => good), but by convention, analysis
 *   may show positive numbers meaning better (higher => good)
 * - 600 * 3.9: ~average 600 PAs/season, 3.9 pitches per PA.
 *   In this way, these numbers expand a stat to be similar to
 *   its value in a broader context, while the impact of a single
 *   pitch would otherwise be a small fraction.
 */
const rescalePitcherMetricForDisplay = (metric) => metric * -600 * 3.9;

/**
 * This converts from the user-readable value to the raw value
 * scale in the DB (e.g. for providing search ranges).
 */
const unscalePitcherMetricForDB = (metric) => metric / (-600 * 3.9);

/*
 * This formats strings to paragraph format.
 */
const paragraphFormatter = (text) => {
  return text.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, (i) => i.toUpperCase());
};

/*
 * This returns a players age given a date of birth string
 */
const getAge = (date, today = DateTime.now(), decimals = 0) => {
  if (!date.isValid) return "--";
  const interval = Interval.fromDateTimes(date, today);
  const dobDecimalFormat = interval.length("years");
  return dobDecimalFormat.toFixed(decimals);
};

/**
 * Helper function to return a list of numeric dropdown options.
 * @param {Number} low first option in the dropdown
 * @param {Number} high last option in the dropdown
 * @param {Number} interval increate the step by this amount
 * @param {Number} zeroPadLength the number of leading zeros, default is none
 * @param {string} prefix a string prefix to appear before all options, an example is "." for a decimal point, default empty string
 */
const generateNumericDropdownOptions = (
  low,
  high,
  interval = 1,
  zeroPadLength = 0,
  prefix = "",
  isAscending = true
) => {
  const options = [];

  for (let i = low; i <= high; i = i + interval) {
    const value = prefix + i.toString().padStart(zeroPadLength, "0");
    options.push({ label: value, value: value, key: value });
  }
  if (!isAscending) {
    return options.reverse();
  }

  return options;
};

/**
 * aggregateLoadStatuses aggregates an array of load statuses
 * into a single load status suitable for deciding if the calling component
 * can be safely loaded
 * @param {*} loadStatuses array of strings of LOAD_STATUS enum type
 * @returns a single string LOAD_STATUS value
 */

const aggregateLoadStatuses = (loadStatuses) => {
  if (_.includes(loadStatuses, LOAD_STATUS.ERROR)) {
    return LOAD_STATUS.ERROR;
  }

  if (
    _.includes(loadStatuses, LOAD_STATUS.UNINITIALIZED) ||
    _.includes(loadStatuses, LOAD_STATUS.LOADING)
  ) {
    return LOAD_STATUS.LOADING;
  }

  // If we make it this far, it should be safe to load the app:

  return LOAD_STATUS.READY;
};

const isEvalPeriod = (player, today) => {
  if (!player?.eligYear) {
    return false;
  }
  const evalPeriodStartDate = dayjs(`1-1-${player.eligYear}`, "M-D-YYYY");
  const evalPeriodEndDate = dayjs(`7-16-${player.eligYear}`, "M-D-YYYY");
  return evalPeriodStartDate < today && today < evalPeriodEndDate;
};

const outsToIP = (numOuts) => {
  const outs = parseInt(numOuts);
  if (Number.isNaN(outs)) {
    return "--";
  } else {
    return `${Math.floor(outs / 3)}.${outs % 3}`;
  }
};

//Returns an error message from an RTKQ error object
const generateErrorMessageFromRTKQ = (error) => {
  return `Status: ${error.status} Name: ${error.data.name}`;
};

/**
 * returns a string matching the format [serviceTimeYears].[serviceTimeDays], where serviceTimeDays is a zero padding string of length 3
 * @param {Number} serviceTimeYears an integer representing the number of years in a player's service time
 * @param {Number} serviceTimeDays an integer representing the number of days in a player's service time
 */
const formatServiceTime = (serviceTimeYears, serviceTimeDays) => {
  if (
    isNaN(serviceTimeYears) ||
    isNaN(serviceTimeDays) ||
    !Number.isInteger(serviceTimeYears) ||
    !Number.isInteger(serviceTimeDays)
  ) {
    return "--";
  }
  return `${serviceTimeYears.toString()}.${serviceTimeDays
    .toString()
    .padStart(3, "0")}`;
};

export {
  clamp,
  getRankColors,
  statColorRank,
  slugifyTitle,
  formatBytes,
  threeDigitPercentiles,
  percentage,
  percentageToDecimal,
  percentageNoZeros,
  numberWithCommas,
  threeDigitDecimals,
  twoDigitDecimals,
  oneDigitDecimal,
  wholeNumber,
  wholeNumberNearestTen,
  formatDateToString,
  arraysEqual,
  encode,
  decode,
  formatNumWithSuffix,
  ordinalSuffix,
  stripSpaces,
  percentToHexString,
  rescalePitcherMetricForDisplay,
  unscalePitcherMetricForDB,
  paragraphFormatter,
  getAge,
  generateNumericDropdownOptions,
  aggregateLoadStatuses,
  isEvalPeriod,
  outsToIP,
  formatDateFromIso,
  decimalToPercentageValue,
  generateErrorMessageFromRTKQ,
  formatServiceTime,
};
