/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import VideoSeeker from "../VideoSeeker/VideoSeeker";
import VideoTime from "../VideoTime/VideoTime";
import VideoControlPanel from "../VideoControlPanel/VideoControlPanel";
import "antd/dist/reset.css";
import { Speed } from "../../hooks/use3DAnimationVideoScrubber";
import { KeyframeData } from "../../services/mockData";
import { SegmentFrameSelector } from "./SegmentFrameSelector";
import { Grid } from "@mui/material";
import { Stadium } from "@mui/icons-material";
import LabeledSwitch from "../common/LabeledSwitch";
import { isNil } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
interface VideoScrubberProps {
  isPlaying: boolean;
  handlePlay: () => void;
  handleScrubber: (newCurrentFrame: number) => void;
  currentFrame: number;
  maxValueScrubb: number;
  onNextFrame: () => void;
  onPreviousFrame: () => void;
  videoDuration: number;
  currentSpeed: Speed;
  onSpeedChange: (speed: Speed) => void;
  onNextKeyframe: () => void;
  onLastKeyframe: () => void;
  keyframesData: KeyframeData[];
  loopVideo?: boolean;
  setLoopVideo?: (loopVideo: boolean) => void;
  segmentFrames?: { startFrame?: number; endFrame?: number };
  setSegmentFrames?: (segment: {
    startFrame?: number;
    endFrame?: number;
  }) => void;
  onShouldShowStadiumChange?: (shouldShowStadium: boolean) => void;
  shouldShowStadium?: boolean;
}

function FrameSelectorContainer({
  labelInput,
  frame,
  maxValueScrubb,
  segmentFrames,
  setSegmentFrames,
}: {
  labelInput: "START" | "END";
  frame: "startFrame" | "endFrame";
  maxValueScrubb: any;
  segmentFrames: any;
  setSegmentFrames: any;
}) {
  return (
    <Grid
      item
      sm={1.8}
      md={1.3}
      lg={1}
      xl={0.7}
      display={{ xs: "none", sm: "block" }}
    >
      <SegmentFrameSelector
        maxValueScrubb={maxValueScrubb}
        labelInput={labelInput}
        frame={frame}
        value={segmentFrames}
        setSegmentFrames={setSegmentFrames}
      />
    </Grid>
  );
}

function VideoScrubber({
  isPlaying,
  handlePlay,
  handleScrubber,
  currentFrame,
  maxValueScrubb,
  onPreviousFrame,
  onNextFrame,
  videoDuration,
  onSpeedChange,
  currentSpeed,
  onNextKeyframe,
  onLastKeyframe,
  keyframesData,
  segmentFrames,
  setSegmentFrames,
  onShouldShowStadiumChange,
  shouldShowStadium,
}: VideoScrubberProps) {
  useHotkeys("space", () => {
    handlePlay();
  });

  useHotkeys("left", () => {
    onPreviousFrame();
  });

  useHotkeys("right", () => {
    onNextFrame();
  });

  useHotkeys("mod+left", () => {
    onLastKeyframe();
  });

  useHotkeys("mod+right", () => {
    onNextKeyframe();
  });

  const VideoSeekerComponent = useMemo(
    () => (
      <VideoSeeker
        segmentFrames={segmentFrames}
        currentFrame={currentFrame}
        handleScrubber={handleScrubber}
        keyframesData={keyframesData}
      />
    ),
    [segmentFrames, currentFrame, handleScrubber, keyframesData]
  );

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={1}
      justifyContent="center"
      alignItems="center"
      pb={2}
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        opacity: 0.8,
      }}
    >
      <Grid item xs={11} display={{ md: "none" }}>
        {VideoSeekerComponent}
      </Grid>
      <Grid item xs={6} sm={3} md={2.5}>
        <VideoControlPanel
          isPlaying={isPlaying}
          playButtonOnClick={handlePlay}
          onNextFrame={onNextFrame}
          onPreviousFrame={onPreviousFrame}
          onSpeedChange={onSpeedChange}
          currentSpeed={currentSpeed}
          onNextKeyframe={onNextKeyframe}
          onLastKeyframe={onLastKeyframe}
        />
      </Grid>
      <FrameSelectorContainer
        maxValueScrubb={maxValueScrubb}
        labelInput={"START"}
        frame={"startFrame"}
        segmentFrames={segmentFrames}
        setSegmentFrames={setSegmentFrames}
      />
      <Grid item md={5} display={{ xs: "none", md: "block" }}>
        {VideoSeekerComponent}
      </Grid>

      <FrameSelectorContainer
        maxValueScrubb={maxValueScrubb}
        labelInput={"END"}
        frame={"endFrame"}
        segmentFrames={segmentFrames}
        setSegmentFrames={setSegmentFrames}
      />
      <Grid item sm={3} md={1.5} display={{ xs: "none", sm: "block" }}>
        <VideoTime
          currentFrame={currentFrame}
          lastFrame={maxValueScrubb}
          videoDuration={videoDuration}
        />
      </Grid>
      {!isNil(shouldShowStadium) && (
        <Grid item>
          <LabeledSwitch
            label={<Stadium />}
            checked={shouldShowStadium || false}
            onClick={(it) => {
              if (onShouldShowStadiumChange) onShouldShowStadiumChange(it);
            }}
            noMenu
          />
        </Grid>
      )}
    </Grid>
  );
}

export default VideoScrubber;
