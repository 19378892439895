// wrapper(s) around mlb statsapi

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const mlbStatsApi = createApi({
  reducerPath: "mlbStatsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://statsapi.mlb.com/api/v1",
  }),

  endpoints: (builder) => ({
    getScheduleData: builder.query({
      query: (yyyymmdd) => {
        return `/schedule?sportId=1,10,11,12,13,14,16&date=${yyyymmdd}`;
      },
    }),
  }),
});
