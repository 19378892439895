import { isNil } from "lodash";
import { PlayerPositionKind } from "./movementSlice";
import type { PerformanceRootState } from "./performanceReduxModule";
import dayjs from "dayjs";

export const selectMovement = (state: PerformanceRootState) =>
  state.movement?.movement;
export const selectComparingMovement = (state: PerformanceRootState) =>
  state.movement?.comparingMovement;
export const selectExtraMovements = (state: PerformanceRootState) =>
  state.movement?.extraMovements || [];
export const selectIsDataCompare = (state: PerformanceRootState) =>
  state.movement.isDataCompare;
export const selectIsSelfCompare = (state: PerformanceRootState) =>
  state.movement.isSelfCompare;
export const selectIsSecondarySelfCompare = (state: PerformanceRootState) =>
  state.movement.isSecondarySelfCompare;
export const selectDataCompSwitch = (state: PerformanceRootState) =>
  state.movement.dataCompValue;
export const selectSelfCompSwitch = (state: PerformanceRootState) =>
  state.movement.selfCompValue;
export const selectSecondarySelfCompSwitch = (state: PerformanceRootState) =>
  state.movement.secondarySelfCompValue;
export const selectCurrentMetric = (state: PerformanceRootState) =>
  state.movement.currentMetric;
export const selectFirstFrameValue = (state: PerformanceRootState) =>
  state.movement.firstFrameValue;
export const selectMaxValueScrubb = (state: PerformanceRootState) =>
  state.movement.maxValueScrubb;
export const selectLastFrameValue = (state: PerformanceRootState) =>
  state.movement.lastFrameValue;
export const selectVideoDurationValue = (state: PerformanceRootState) =>
  state.movement.videoDurationValue;
export const selectCurrentFrameValue = (state: PerformanceRootState) =>
  state.movement.currentFrameValue;
export const selectSelectedMetric = (state: PerformanceRootState) =>
  state.movement.selectedMetric;
export const selectTimeSeriesMetrics = (state: PerformanceRootState) =>
  state.movement.timeSeriesMetrics;
export const selectPlayerSelectedDataComp = (state: PerformanceRootState) =>
  state.movement.playerSelectedDataComp;
export const selectPeriodSelectorValue = (state: PerformanceRootState) =>
  state.movement.periodSelectorValue;
export const selectDataIndexingValue = (state: PerformanceRootState) =>
  state.movement.dataIndexing;
export const selectIncludeOppositeSide = (state: PerformanceRootState) =>
  state.movement.includeOppositeSide;
export const selectIsNonDominant = (state: PerformanceRootState) =>
  state.movement.isNonDominant;
export const selectPitchIndexing = (state: PerformanceRootState) =>
  state.movement.pitchIndexing;
export const selectDataCompValues = (state: PerformanceRootState) =>
  state.movement.dataCompValues;
export const selectSelfCompValues = (state: PerformanceRootState) =>
  state.movement.selfCompValues;
export const selectSelfCompOptions = (state: PerformanceRootState) =>
  state.movement.selfCompOptions;
export const selectSecondarySelfCompOptions = (state: PerformanceRootState) =>
  state.movement.secondarySelfCompOptions;
export const selectEntryType = (state: PerformanceRootState) =>
  state.movement.entryType;
export const selectAssessmentType = (state: PerformanceRootState) =>
  state.movement.assessmentType;
export const selectMeasureExpandedViewTimePeriod = (
  state: PerformanceRootState
) => state.movement.measureExpandedViewTimePeriod;
export const selectShowNewData = (state: PerformanceRootState) =>
  state.movement.showNewData;

export const selectPlayerDashSettings =
  (position: PlayerPositionKind) => (state: PerformanceRootState) =>
    state.movement.playerDashSettings[position];
export const selectWorkloadKeyMetrics =
  (position: PlayerPositionKind) => (state: PerformanceRootState) =>
    state.movement.workloadKeyMetrics[position];
export const selectSelectedMotionType = (state: PerformanceRootState) =>
  state.movement.selectedMotionType;
export const selectWorkloadOutingDate = (state: PerformanceRootState) => {
  const value = state.movement.workloadOutingDate;
  return isNil(value) ? null : dayjs(value);
};
export const selectSyncReferenceKeyFrame = (state: PerformanceRootState) =>
  state.movement.syncReferenceKeyFrame;
export const selectPitchingKinatraxReportScrubberLock = (
  state: PerformanceRootState
) => state.movement.pitchingKinatraxReportScrubberLock;
export const selectSkeleModalOpen = (state: PerformanceRootState) =>
  state.movement.skeleModalOpen;
