import React from "react";
import { Box } from "@mui/material";
import { CollapsiblePanelButton } from "../common/CollapsiblePanel";
import { useDispatch } from "react-redux";
import { setSkeleModalOpen } from "../../redux/movementSlice";

const AvgSkeleModal = () => {
  const dispatch = useDispatch();

  return (
    <Box
      style={{
        top: 360,
        right: "-24px",
        position: "absolute",
        zIndex: 110,
      }}
    >
      <CollapsiblePanelButton
        title="Avg Skele"
        handleClick={() => {
          dispatch(setSkeleModalOpen(true));
        }}
        orientation="left"
      />
    </Box>
  );
};

export default AvgSkeleModal;
