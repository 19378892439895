import { isNil, mapValues } from "lodash";
import { pitchTypesByPriority } from "../../../utils/metrics";
import { TrendType } from "../../common/trendsConfigurations";
import { flattenTeamDashMetrics } from "../../../hooks/useTeamPlayers";
import {
  stringFromParts,
  toLocaleString,
  unlessEmpty,
} from "../../../utils/strings";

export type WorkloadMetricDefinition = {
  label: string;
  description?: string;
  unit: string;
  key: string;
  type?: string;
};

export type WorkloadMetric = WorkloadMetricDefinition & {
  value?: number;
  trend?: TrendType | string;
  dataComp?: { from: number; to: number };
  event?: string;
};

export interface WorkloadMetrics {
  pitcher: WorkloadMetricDefinition[];
  positionPlayer: WorkloadMetricDefinition[];
}

export const formatWorkloadMetricValue = (value?: number) =>
  toLocaleString(value);

export const formatWorkloadMetricLabel = (metric?: WorkloadMetric) =>
  isNil(metric)
    ? ""
    : stringFromParts([
        metric.label,
        unlessEmpty(metric.event, ` (${metric.event})`),
      ]);

export const categorizedWorkloadMetricsDefinition = {
  pitcher: [
    {
      key: "category_general",
      label: "General",
      children: [
        {
          label: "Pitch Count",
          description: "Total number of pitches in game.",
          key: "total_count_game",
          unit: "pitches",
        },
        {
          label: "Stressful #",
          description:
            "Total “stressful” pitches in game based on pitch count, leverage index, and batter projections. Pitch count and leverage index can be represented as partial stressful pitches with a gradient. If more than 17 pitches are thrown in an inning, all remaining pitches are considered stressful. All pitches with a leverage index over 1.5 are considered stressful.",
          key: "stressful_pitch_count",
          unit: "pitches",
        },
        {
          label: "Stressful %",
          description: "Rate of stressful/total game pitches",
          key: "stressful_pitch_rate",
          unit: "%",
        },
        {
          label: "Time b/w Pitches",
          description:
            "Time (in seconds) between pitches (excluding time between last pitch of previous inning and first pitch of next inning)",
          key: "avg_seconds_since_last_pitch",
          unit: "sec",
        },
        {
          label: "Time b/w Inn",
          description: "Time (in minutes) between innings pitched",
          key: "avg_minutes_since_last_inning_pitch",
          unit: "min",
        },
        {
          label: "Pitches/Inning",
          description: "Pitches made per inning",
          key: "avg_pitches_per_inning",
          unit: "",
        },
        {
          label: "Temp",
          description: "Average temperature of the game",
          key: "avg_temperature",
          unit: "°F",
        },
        {
          label: "Humidity",
          description: "Average humidity of the game",
          key: "avg_humidity",
          unit: "%",
        },
        {
          label: "Bullpen Throws",
          description: "Bullpen throws logged (from the Data Entry Form)",
          key: "bullpen_updown_throws",
          unit: "pitches",
        },
        {
          label: "BP Up/Downs",
          description:
            "Bullpen up/downs done in-game by relief pitchers (from the Data Entry Form)",
          key: "bullpen_downs", // Note: may change to "bullpen_updowns"
          unit: "",
        },
        {
          label: "Non-Game #",
          key: "total_count_nongame",
          description: "Total non-game throws",
          unit: "",
        },
        {
          label: "Avg Leverage Idx",
          key: "avg_leverage",
          description: "Average Leverage Index",
          unit: "",
        },
        {
          label: "Innings Pitched",
          key: "innings_pitched",
          description: "Innings Pitched",
          unit: "",
        },
      ],
    },
    {
      key: "category_primary_fb",
      label: "Primary Fastball",
      children: [
        {
          label: "FB Velocity",
          description:
            "Velocity for most commonly thrown fastball (4FB, 2FB, or CT, depending on pitcher usage)",
          key: "primary_fb_velocity",
          unit: "mph",
        },
        {
          label: "FB Spin Factor",
          description:
            "Spin Factor [rate/velocity] for most commonly thrown fastball (4FB, 2FB, or CT, depending on pitcher usage)",
          key: "primary_fb_spin_factor",
          unit: "rpm/mph",
        },
        {
          label: "FB Spin Rate",
          description:
            "Spin Rate for most commonly thrown fastball (4FB, 2FB, or CT, depending on pitcher usage)",
          key: "primary_fb_spin_rate",
          unit: "rpm",
        },
        {
          label: "FB Count",
          description: "Count for most commonly thrown fastball",
          key: "primary_fb_pitch_count",
          unit: "pitches",
        },
      ],
    },
    {
      key: "category_all_pitches",
      label: "All Pitches",
      children: pitchTypesByPriority.map((pitchType) => ({
        key: `category_pitch_${pitchType.toLowerCase()}`,
        label: pitchType,
        children: [
          {
            label: `${pitchType} Velocity`,
            description:
              "Average velocity for selected pitch type (4FB, 2FB, CT, SL, CB, SW, CH, SF, etc)",
            key: `avg_velocity_${pitchType.toLowerCase()}`,
            unit: "mph",
          },
          {
            label: `${pitchType} Rel Ext`,
            description: "Average release extension for selected pitch type",
            key: `avg_extension_${pitchType.toLowerCase()}`,
            unit: "ft",
          },
          {
            label: `${pitchType} Arm Slot`,
            description: "Average arm slot for selected pitch type",
            key: `avg_armslot_${pitchType.toLowerCase()}`,
            unit: "°",
          },
          {
            label: `${pitchType} Usage`,
            description:
              "Usage rates for selected pitch type (4FB, 2FB, CT, SL, CB, SW, CH, SF, etc)",
            key: `pitch_percent_${pitchType.toLowerCase()}`,
            unit: "%",
          },
          {
            label: `${pitchType} Counts`,
            description: "Counts for each pitch type",
            key: `pitch_count_${pitchType.toLowerCase()}`,
            unit: "pitches",
          },
          {
            label: `${pitchType} Run Value`,
            description:
              "pRV measures the average expected run value of a pitcher's repertoire or a given pitch scaled per 600 batters faced.",
            key: `avg_prv_${pitchType.toLowerCase()}`,
            unit: "",
          },
          {
            label: `${pitchType} Stuff`,
            description:
              "Stuff measures the average expected run value of a pitcher's repertoire or a given pitch scaled per 600 batters faced.",
            key: `avg_stuff_${pitchType.toLowerCase()}`,
            unit: "",
          },
          {
            label: `${pitchType} Max Velocity`,
            key: `max_velocity_${pitchType.toLowerCase()}`,
            description: "",
            unit: "",
          },
          {
            label: `${pitchType} Max Spin Rate`,
            key: `max_spin_rate_${pitchType.toLowerCase()}`,
            description: "",
            unit: "",
          },
          {
            label: `${pitchType} Avg IVB`,
            key: `avg_vert_break_induced_${pitchType.toLowerCase()}`,
            description: "",
            unit: "",
          },
          {
            label: `${pitchType} Aveg HB`,
            key: `avg_horz_break_${pitchType.toLowerCase()}`,
            description: "",
            unit: "",
          },
          {
            label: `${pitchType} Avg Spin Eff.`,
            key: `avg_spin_efficiency_${pitchType.toLowerCase()}`,
            description: "",
            unit: "",
          },
          {
            label: `${pitchType} Rel Height`,
            key: `avg_release_height_${pitchType.toLowerCase()}`,
            description: "",
            unit: "",
          },
          {
            label: `${pitchType} Rel Side`,
            key: `avg_release_side_${pitchType.toLowerCase()}`,
            description: "",
            unit: "",
          },
        ],
      })),
    },
  ],
  positionPlayer: [
    {
      key: "category_general",
      label: "General",
      children: [
        {
          label: "Plate App",
          description: "Total number of game plate appearances",
          key: "plate_appearance_count",
          unit: "",
        },
        {
          label: "Bat Speed",
          description:
            "[Statcast] Average bat speed on balls-in-play or outs (currently including all contact)",
          key: "avg_bat_speed",
          unit: "mph",
        },
        {
          label: "Exit Velocity",
          description:
            "[Statcast] Average exit velocity on balls-in-play or outs (currently including all contact)",
          key: "avg_exit_velo",
          unit: "mph",
        },
        {
          label: "Launch Angle",
          description:
            "[Statcast] Average launch angle on balls-in-play or outs (currently including all contact)",
          key: "avg_launch_angle",
          unit: "°",
        },
        {
          label: "Innings Fielded",
          description: "Number of innings played in the field",
          key: "innings_fielded",
          unit: "innings",
        },
        {
          label: "Consec Game Days",
          description:
            "Consecutive days of games with more than 1 plate appearance",
          key: "consecutive_games_with_more_than_1_pa",
          unit: "days",
        },
      ],
    },
    {
      key: "category_swings",
      label: "Swings",
      children: [
        {
          label: "Game Swings",
          description: "Total number of swings during the game",
          key: "game_swings",
          unit: "swings",
        },
        {
          label: "Pregame Swings",
          description: "Total number of pregame swings [Data Entry Forms]",
          key: "pregame_swings",
          unit: "swings",
        },
        {
          label: "Total Swings",
          description: "Sum of game and pregame swings",
          key: "total_swings",
          unit: "swings",
        },
      ],
    },
    {
      key: "category_fielding",
      label: "Fielding",
      children: [
        {
          label: "Fielding Jump",
          description: "Feet covered in “correct” direction (0-3 sec)",
          key: "fielding_jump",
          unit: "ft",
        },
        {
          label: "Fielding React",
          description: "Feet covered in any direction (0-1.5 sec)",
          key: "fielding_reaction",
          unit: "ft",
        },
        {
          label: "Fielding Burst",
          description: "Feet covered in any direction (1.6-3.0 sec)",
          key: "fielding_burst",
          unit: "ft",
        },
        {
          label: "Fielding Route",
          description: "Feet covered against direct route",
          key: "fielding_directness",
          unit: "ft",
        },
      ],
    },
    {
      key: "category_running",
      label: "Running",
      children: [
        {
          label: "Run Dist",
          description: "[Statcast] Total distance covered",
          key: "total_distance",
          unit: "ft",
        },
        {
          label: "Sprint Speed",
          description: "[Metscast] Max sprint speed measured in-game",
          key: "top_speed",
          unit: "fps",
        },
        {
          label: "Efforts > 90%",
          description:
            "Total sprint efforts over 90% max player velocity (from player history)",
          key: "efforts_over_90",
          unit: "",
        },
        {
          label: "Efforts > 70%",
          description:
            "Total sprint efforts over 70% max player velocity (from player history)",
          key: "efforts_over_70",
          unit: "",
        },
        {
          label: "HR > 90%",
          description:
            "[Catapult] Total minutes with heart rate above 90% of max heart rate (from age calculation)",
          key: "hr_above_90perc",
          unit: "min",
        },
        {
          label: "HR > 70%",
          description:
            "[Catapult] Total minutes with heart rate between 70% - 90% of max heart rate (from age calculation)",
          key: "hr_above_70perc",
          unit: "min",
        },
      ],
    },
  ],
};

export const workloadDefaultKeyMetrics = {
  positionPlayer: [
    "plate_appearance_count",
    "avg_bat_speed",
    "avg_exit_velo",
    "innings_fielded",
    "consecutive_games_with_more_than_1_pa",
    "total_swings",
    "efforts_over_70",
    "efforts_over_90",
    "total_distance",
  ],
  pitcher: [
    "total_count_game",
    "stressful_pitch_count",
    "stressful_pitch_rate",
    "avg_seconds_since_last_pitch",
    "avg_pitches_per_inning",
    "total_count_nongame",
    "primary_fb_velocity",
    "primary_fb_pitch_count",
    "avg_temperature",
  ],
};

export const workloadMetricsDefinition: WorkloadMetrics = mapValues(
  categorizedWorkloadMetricsDefinition,
  (it) => flattenTeamDashMetrics<WorkloadMetricDefinition>(it)
);
