import React, { useEffect, useState } from "react";
import { getBounds, flattenReverseOrDefault } from "../../utils/metrics";
import {
  fixId,
  formatData,
  formatPlotLine,
  formatSecondaryData,
  formatTimeSeriesAxes,
  formatYAxes,
  getPointsByTrialIndex,
  mergeUnits,
} from "../../utils/charts";
import useFramesData from "../../hooks/useFramesData";
import {
  selectComparingMovement,
  selectSelectedMotionType,
} from "../../redux/selectors";
import { useSelector } from "react-redux";
import { isEmpty, isNil, uniqBy } from "lodash";
import dayjs from "dayjs";
import MemoizedHighcharts from "./MemoizedHighcharts";
import { MotionType } from "../common/MotionType";

interface LineChartProps {
  data: any[];
  plotLines?: any[];
  height?: string;
  width?: string;
  secondaryData?: any[];
  showTooltip?: boolean;
  tooltipDirection?: "column" | "row";
  stickyTracking?: boolean;
  marginTop?: number;
  marginBottom?: number;
  setInitialZoom?: boolean;
  noTooltipLineBreak?: boolean;
  trendsTimeSeries?: boolean;
  noShared?: boolean;
  hoveredColor?: string;
  setHoveredColor?: (color: string | undefined) => void;
  extra?: {
    setChart?: (param?: any) => void;
  };
  includeSelf?: boolean;
  showReversed?: boolean;
}

const plotZeroLine = (yAxis: any) => ({
  ...yAxis,
  plotLines: [
    ...(yAxis.plotLines ?? []),
    {
      value: 0,
      color: "grey",
      width: 1,
      dashStyle: "dash",
      zIndex: 4,
    },
  ],
});

const makeYAxisLabel = (props: {
  x: number;
  y: number;
  verticalAlign: string;
  text?: string;
}) => ({
  point: { yAxis: 0, x: 0, y: 0 },
  backgroundColor: "none",
  borderWidth: 0,
  style: {
    color: "grey",
  },
  ...props,
});

const lineChartMetrics = ["MKH", "MS", "FFC", "MER", "BR"];
const LineChart = ({
  data,
  height,
  width,
  plotLines = [],
  secondaryData = [],
  showTooltip = false,
  tooltipDirection = "row",
  stickyTracking = false,
  setInitialZoom = false,
  noTooltipLineBreak = false,
  marginTop = 45,
  marginBottom,
  noShared = false,
  trendsTimeSeries = false,
  extra,
  setHoveredColor,
  includeSelf = false,
  showReversed = false,
}: LineChartProps) => {
  const [chart, setChart] = useState<any>(null);
  const [mainSeries, setMainSeries] = useState<any[]>([]);
  const [secondarySeries, setSecondarySeries] = useState<any[]>([]);
  const { trial: comparingTrial } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);

  const {
    firstFrameValue,
    lastFrameValue,
    toMillisecondsFromBrOffset,
    currentFrame,
    rangeToMs,
    getFrameForMainTrial,
    frameToMillis,
  } = useFramesData();

  const currentMetrics = data.filter((metric, index) => metric.index === index);
  const filteredData = data.filter(
    (it) =>
      includeSelf ||
      (currentMetrics.some((metric) => it?.id?.includes(metric?.id)) &&
        !it?.label?.includes("Comp") &&
        !it?.label?.includes("Self"))
  );
  const filteredSecondaryData = secondaryData.filter((it) =>
    currentMetrics.some((metric) => it?.id?.includes(metric?.id))
  );
  const formattedData = formatData(filteredData);
  const formattedSecondaryData = formatSecondaryData(
    mergeUnits(filteredSecondaryData, currentMetrics)
  );

  const filteredLines = plotLines.filter((line) =>
    lineChartMetrics.includes(line?.label?.text)
  );
  const mappedPlotlines = filteredLines.map(formatPlotLine);

  const xValues = rangeToMs(0, lastFrameValue);
  const xBounds = getBounds(rangeToMs(firstFrameValue, lastFrameValue));
  const ranges = {
    start: toMillisecondsFromBrOffset(firstFrameValue),
    end: toMillisecondsFromBrOffset(lastFrameValue),
  };

  const yAxisLabels = isEmpty(filteredData)
    ? []
    : uniqBy(
        filteredData.map((it) => ({
          positive: it.positiveValuesLabel,
          negative: it.negativeValuesLabel,
        })),
        "positive"
      );

  const showYAxisLabels = !isEmpty(yAxisLabels);

  useEffect(() => {
    formatTimeSeriesAxes({
      data: formattedData,
      xValues,
      key: "primaryData",
      ranges,
    }).then((value: any) => {
      setMainSeries(value);
    });
  }, [formattedData]);

  useEffect(() => {
    formatTimeSeriesAxes({
      data: formattedSecondaryData,
      xValues,
      key: "secondaryData",
      ranges,
    }).then((value) => {
      setSecondarySeries(value);
    });
  }, [formattedSecondaryData]);

  const fixedSecondarySeries = secondarySeries.filter((aSeries) =>
    mainSeries
      .map((it) => fixId(it?.id, true))
      .includes(fixId(aSeries.id, true))
  );

  const addTrialIndex = (series: any) => ({
    ...series,
    data: series?.data?.map(([x, y]: number[]) => ({
      x,
      y,
      trialIndex: series?.trialIndex,
    })),
  });

  const series = flattenReverseOrDefault(
    mainSeries.map(addTrialIndex),
    fixedSecondarySeries,
    showReversed
  );

  const yAxis = formatYAxes(
    mainSeries,
    mergeUnits(secondarySeries, currentMetrics),
    trendsTimeSeries
  );

  const options = {
    chart: {
      height: height,
      width: width,
      zoomType: "x",
      marginTop,
      marginBottom,
      resetZoomButton: { theme: { display: "none" } },
      events: {
        load: function () {
          const initialChart: any = this;
          setChart(initialChart);

          if (extra?.setChart) {
            extra.setChart(this);
          }

          if (setInitialZoom) {
            if (motionType === MotionType.Batting) {
              initialChart.xAxis?.[0]?.setExtremes(-35, 20);
            } else {
              const ffc = getFrameForMainTrial("FFC");
              const ffcMinusMargin = toMillisecondsFromBrOffset(ffc - 40);
              const brPlusMargin = frameToMillis(20);

              initialChart.xAxis?.[0]?.setExtremes(
                ffcMinusMargin,
                brPlusMargin
              );
            }

            initialChart.showResetZoom();
          }
        },
      },
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: showTooltip,
      split: !noShared,
      shared: !noShared,
      outside: true,
      useHTML: true,
      style: {
        opacity: 0,
        zIndex: 1000,
      },
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject,
        tooltip1: Highcharts.Tooltip
      ) {
        let xCrosshair: any = document.getElementsByClassName(
          "ptd-x-axis-crosshair"
        );
        let yCrosshair: any = document.getElementsByClassName(
          "ptd-y-axis-crosshair"
        );

        const options: any = this.point?.series?.options;

        if (xCrosshair[0]) {
          xCrosshair[0].style.stroke = options?.lineColor;
        }
        if (yCrosshair[0]) {
          yCrosshair[0].style.stroke = options?.lineColor;
        }
        const tooltipBorderColor = trendsTimeSeries
          ? options?.lineColor
          : "#888b8d";
        const tooltipCustomLeft = trendsTimeSeries
          ? "30px"
          : series.length === 2
          ? "0"
          : "55px";
        let tooltip = `<div
        style="
          position: absolute;
          top: ${tooltip1.chart.plotHeight}px;
          left: ${tooltipCustomLeft};
          padding: 6px;
          border-radius: 6px;
          border: solid 1px ${tooltipBorderColor};
          background-color: #fff;
          z-index: 1000;"
          >
          ${trendsTimeSeries ? "" : "<b>Time:</b>"} ${Math.floor(
          Number(this.x) * 10
        )}ms
          </div>`;

        if (trendsTimeSeries) {
          tooltip += `<div
            style="
            position:absolute;
            top: -25px;
            left: 20px;
            padding:6px;
            border-radius:6px;
            border: solid 1px ${options?.lineColor};
            background-color: #fff;
            z-index: 1000;
            font-size:14px">${dayjs(options?.date).format("MM/DD/YY")}</div>`;
          tooltip += `<div
            style="
            position:absolute;
            top: ${this.point?.plotY}px;
            left: -${(this?.point?.plotX || 0) - 20}px;
            padding:6px;
            border-radius:6px;
            border: solid 1px ${options?.lineColor};
            background-color: #fff;
            z-index: 1000;
            font-size:14px">${Math.floor(Number(this.y))}</div>`;
          return tooltip;
        } else {
          let spans = ``;
          const metricsLength = data.length / (comparingTrial?.id ? 2 : 1);
          let fixedIndex = metricsLength;

          const main = getPointsByTrialIndex(this?.points, 1);
          const secondary = getPointsByTrialIndex(this?.points, 2);

          const points = [...main, ...secondary];

          points?.forEach((point, index) => {
            const isSelf = point?.color.includes("self");
            const selfIndex = isSelf ? index : "";

            if (index + 1 > fixedIndex) {
              fixedIndex = fixedIndex + metricsLength;
            }

            if (data.length <= index && !isSelf) return;

            const trial = fixedIndex / metricsLength;
            const fixedYValue = Number(point.y).toFixed(1);
            const color =
              data[index]?.color && !isSelf
                ? data[index]?.color?.includes("#")
                  ? data[index]?.color
                  : `var(--${data[index].color})`
                : point.color;

            spans += `<div style="display: flex; flex-direction: row; margin-right: 8px;">
          <span style="color:${color}; font-weight:bold">${
              isSelf ? `Self ${selfIndex}` : `Trial ${trial}`
            }:</span>
          <span style="font-weight:normal">${fixedYValue}</span>
          ${!noTooltipLineBreak && metricsLength - 1 == index ? "<br/>" : ""}
          </div>`;
          });

          tooltip += `<div
          style="
            position:absolute;
            top:-45px;
            border-radius: 6px;
            border: solid 1px #888b8d;
            background-color: #fff;
            padding:4px;
            z-index:1000;
            display: flex;
            flex-direction: ${tooltipDirection};
            ">${spans}</div>`;
          return tooltip;
        }
      },
      positioner: function (
        this: Highcharts.Tooltip,
        labelWidth: number,
        labelHeight: number,
        point: Highcharts.TooltipPositionerPointObject
      ) {
        const chart = this.chart;

        const fixedWidth = series.length === 2 ? 120 : 230;
        const fixedHeight = trendsTimeSeries ? 0 : 400;
        return {
          x: Math.min(
            Math.max(5, point.plotX + chart.plotLeft - fixedWidth / 2),
            chart.chartWidth - labelWidth
          ),
          y: chart.plotTop - labelHeight - fixedHeight,
        };
      },
    },
    plotOptions: {
      series: {
        turboThreshold: 0,
        lineWidth: 2,
        name: "",
        marker: {
          enabled: false,
        },
        events: {
          mouseOut: () => {
            if (setHoveredColor) {
              setHoveredColor(undefined);
            }
          },
        },
        point: {
          events: {
            mouseOver: function (point: any) {
              if (setHoveredColor && !isNil(point?.target?.color)) {
                setHoveredColor(point?.target?.color);
              }
            },
          },
        },
        softThreshold: false,
        animation: false,
        stickyTracking,
        states: {
          hover: { halo: { size: 0 } },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    xAxis: chart
      ? {
          min: isFinite(xBounds.min) ? xBounds.min : 0,
          max: isFinite(xBounds.max) ? xBounds.max : 10,
          gridLineWidth: 1,
          crosshair: {
            className: trendsTimeSeries ? "ptd-x-axis-crosshair" : "",
            width: trendsTimeSeries ? 2 : 1,
            enabled: showTooltip,
          },
          gridLineDashStyle: "shortDash",
          plotLines: [],
          labels: {
            formatter: (self: any) => `${self?.value * 10}ms`,
          },
        }
      : {},
    annotations: showYAxisLabels
      ? yAxisLabels.map((yAxisLabel: any) => ({
          labels: [
            makeYAxisLabel({
              x: -5,
              y: -10,
              verticalAlign: "bottom",
              text: yAxisLabel.positive,
            }),
            makeYAxisLabel({
              x: -5,
              y: 10,
              verticalAlign: "top",
              text: yAxisLabel.negative,
            }),
          ],
        }))
      : [],
    yAxis: chart ? (showYAxisLabels ? yAxis.map(plotZeroLine) : yAxis) : {},
    //Favoring series property over useEffect + updateOrReplaceSeries because of missing series in chart
    //series prop + memoized highcharts seems like it's working better
    series,
  };

  // Manually control the cursor to avoid a full chart re-render when frame changes
  useEffect(
    () => {
      if (isNil(chart) || isNil(chart.xAxis[0])) {
        return;
      }

      const cursorLine = formatPlotLine({
        id: "current-frame-line",
        label: { text: "liveCursor" },
        value: toMillisecondsFromBrOffset(currentFrame),
        animation: false,
      });

      // This creates or updates the plotline
      chart.xAxis[0].update({ plotLines: [...mappedPlotlines, cursorLine] });
    },
    // We include options here so the line doesn't disappear when options change
    [chart, currentFrame, options]
  );

  return <MemoizedHighcharts options={options} />;
};

export default LineChart;
