import React, { useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highcharts.js";
import dayjs from "dayjs";
import {
  getBounds,
  getDateArrayData,
  shouldTreatZeroAsNull,
  truncateNumberToDecimals,
  toScientificNotation,
} from "../../utils/metrics";
import { ExpandedMetricsDataIndexing } from "../PlayerDashboard/ExpandedMetricsDataIndexing";
import { Grid } from "@mui/material";
import RegressionLegendItem from "./RegressionLegendItem";
import {
  Dictionary,
  first,
  flatten,
  isEmpty,
  isEqual,
  isNil,
  last,
} from "lodash";
import { getDatesInRange } from "../../utils/charts";
import { DataPoint } from "regression";
import {
  getHydrationAxisValues,
  getHydrationColor,
  getHydrationLabel,
  getHydrationValueByAxis,
  getIsHydration,
} from "../common/hydrationColors";
import { Unless } from "react-if";
import {
  Temperature,
  temperatureForDate,
  temperatureMetricColor,
  temperatureMetricLabel,
} from "../../utils/temperatureMetric";

interface Bounds {
  min?: number;
  max?: number;
}

interface RegressionLineChartProps {
  data: any;
  temperatureMarkers?: boolean;
  temperatureData?: Dictionary<Temperature>;
  regressions: any;
  metric?: any;
  secondaryData?: any;
  height?: number;
  width?: number;
  dataIndexing: ExpandedMetricsDataIndexing;
  bounds: { x: Bounds; y?: Bounds };
  setBounds: (bounds?: any) => void;
  setSelection: (selection?: any) => void;
  units?: { x?: string; y?: string };
  reggresionLineColor: string;
  decimals?: number;
}

const COLORS = ["black", "var(--self-accent-color)"];
const SECONDARY_DATA_COLORS = ["#F1F5FF", "#EBE8FC"];

const RegressionLineChart = ({
  data = [],
  temperatureMarkers = false,
  temperatureData,
  metric,
  regressions,
  secondaryData = [],
  dataIndexing,
  height = 300,
  width,
  bounds,
  setBounds,
  setSelection,
  units,
  reggresionLineColor,
  decimals,
}: RegressionLineChartProps) => {
  const isHydration = getIsHydration(metric);
  const { equation = [], points = [], r2 = 0 } = regressions?.data;
  const isDateIndexing = dataIndexing === ExpandedMetricsDataIndexing.Date;
  const allDataValues = data.map((series: any) =>
    getDateArrayData(
      series?.data,
      dataIndexing,
      !isDateIndexing ? "date" : undefined
    )
  );
  const dataValues = shouldTreatZeroAsNull(metric?.metric)
    ? allDataValues.map((data: DataPoint[]) =>
        // eslint-disable-next-line no-unused-vars
        data.filter(([_x, y]) => y !== 0)
      )
    : allDataValues;
  const regressionsData = points.filter((point: any) =>
    dataValues[0].find((it: number[]) => point[0] === it[0])
  );
  const from = dayjs(first(first(dataValues[0])));
  const to = dayjs(first(last(dataValues[0])));

  const tickPositions = isDateIndexing
    ? getDatesInRange(from, to, dataValues[0]?.length)
    : dataValues[0]?.map((it: any) => it[0]);

  const mainSeries = dataValues.map((values: any, index: number) => ({
    ...data[index],
    data: values.map(([x, y, date]: any[]) => {
      const temperature = temperatureForDate(dayjs(date ?? x), temperatureData);
      const color = isHydration
        ? getHydrationColor(`${y}`)
        : temperatureMarkers
        ? temperatureMetricColor(temperature)
        : undefined;
      return {
        x,
        y: isHydration ? getHydrationAxisValues(`${y}`) : y,
        date,
        color: color ?? "white",
      };
    }),
    marker: { symbol: "circle", lineColor: COLORS[index] },
    color: COLORS[index],
  }));

  const regressionSeries = {
    ...regressions,
    data: regressionsData,
    color: reggresionLineColor,
    dashStyle: "longDash",
    marker: false,
    enableMouseTracking: false,
  };

  const secondarySeries = secondaryData.map((it: any, index: number) => ({
    ...it,
    type: "arearange",
    lineWidth: 0,
    zIndex: 0,
    marker: { enabled: false },
    enableMouseTracking: false,
    color: SECONDARY_DATA_COLORS[index],
  }));

  const series = flatten([secondarySeries, regressionSeries, mainSeries]);

  useEffect(() => {
    const xValues = mainSeries[0]?.data.map((it: any) => it.x) || [];
    const xBounds = getBounds(xValues);

    if (!isEqual(bounds?.x, xBounds)) {
      setBounds(xBounds);
    }
  }, [mainSeries]);

  const options = {
    title: { text: "" },
    legend: { enabled: false },
    credits: { enabled: false },
    chart: {
      height,
      width,
      zoomType: "x",
      resetZoomButton: { theme: { display: "none" } },
      events: {
        load: function () {
          const chart: any = this;
          const { min, max } = chart?.xAxis?.[0];

          setBounds({ min, max });
        },
        selection: function (event: any) {
          setSelection(event);
          event.preventDefault();
        },
      },
    },
    xAxis: {
      min: !isNil(bounds?.x?.min) ? bounds.x.min : undefined,
      max: !isNil(bounds?.x?.max) ? bounds.x.max : undefined,
      type: isDateIndexing ? "datetime" : "string",
      tickPositions,
      labels: {
        formatter: function (): string {
          const label: any = this;

          return isDateIndexing
            ? dayjs(label.value).format("M/D")
            : `#${label.value}`;
        },
      },
    },
    yAxis: {
      min: !isNil(bounds?.y?.min) ? bounds?.y?.min : undefined,
      max: !isNil(bounds?.y?.max) ? bounds?.y?.max : undefined,
      title: {
        text: data?.[0]?.metric?.label || "",
      },
      labels: {
        formatter: function () {
          const label: any = this;

          return isHydration
            ? getHydrationLabel(getHydrationValueByAxis(`${label.value}`))
            : `${label.value}${units?.y}`;
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          lineWidth: 1,
          radius: 6,
        },
      },
    },
    tooltip: {
      enabled: true,
      split: false,
      shared: false,
      outside: true,
      useHTML: true,
      crosshairs: {
        color: "black",
        dashStyle: "solid",
      },
      style: {
        opacity: 0,
        zIndex: 1000,
      },
      formatter: function () {
        const chart: any = this;
        const xValue = !isDateIndexing ? chart.point?.date : chart.x;
        const date = dayjs(xValue);

        let tooltip = `<div 
          style="
            position: absolute; 
            top: 680px;
            left: 80px;
            padding: 4px;
            border-radius: 6px;
            border: solid 1px #888b8d;
            background-color: #fff;
            z-index: 1000;"
          >
            ${date.format("MM/DD/YY")}
          </div>`;

        const temperature = temperatureForDate(date, temperatureData);

        const TemperatureSpan = isNil(temperature)
          ? ""
          : `<span style="font-weight: bold; color: ${temperatureMetricColor(
              temperature
            )};">${temperatureMetricLabel(temperature)}</span>`;

        const YValue = isHydration
          ? getHydrationLabel(getHydrationValueByAxis(`${chart.y}`))
          : truncateNumberToDecimals(chart.y, decimals);
        const YValueContainer = `<div style="display: flex; flex-direction: row; gap: 5px;">
          <span style="font-weight:normal">${YValue}</span>${TemperatureSpan}
        </div>`;

        tooltip += `<div
          style="
            position:absolute;
            top: 380px;
            left: 80px;
            border-radius: 6px;
            border: solid 1px #888b8d;
            background-color: #fff;
            padding:4px;
            z-index:1000;
            display: flex;
          ">${YValueContainer}</div>`;

        return tooltip;
      },
      positioner: function (
        this: Highcharts.Tooltip,
        labelWidth: number,
        labelHeight: number,
        point: Highcharts.TooltipPositionerPointObject
      ) {
        const chart = this.chart;

        const fixedWidth = series.length === 2 ? 120 : 230;
        return {
          x: Math.min(
            Math.max(5, point.plotX + chart.plotLeft - fixedWidth / 2),
            chart.chartWidth - labelWidth
          ),
          y: chart.plotTop - labelHeight - 400,
        };
      },
    },
    series,
  };

  const regressionLegend = isEmpty(equation)
    ? ""
    : `y = ${toScientificNotation(equation[0])}x + ${toScientificNotation(
        equation[1]
      )}`;

  return (
    <Grid container justifyContent="center" mb={2}>
      <HighchartsReact highcharts={Highcharts} options={options} />

      <Grid container item flexDirection="row" justifyContent="center">
        <RegressionLegendItem
          label="AtheleteData"
          width="150px"
          iconProps={{
            height: "10px",
            width: "10px",
            borderWidth: "1px",
            borderColor: "black",
            borderStyle: "solid",
            borderRadius: "100%",
          }}
        />

        <RegressionLegendItem
          label="Data Comp"
          width="150px"
          iconProps={{
            height: "10px",
            width: "16px",
            backgroundColor: "#f1f5ff",
          }}
        />

        <Unless condition={isHydration}>
          <RegressionLegendItem
            label={`Regression (${regressionLegend}, R² = ${Number(
              r2
            ).toExponential(2)})`}
            width="206px"
            fontSize="11px"
            iconProps={{
              height: "2px",
              width: "16px",
              borderWidth: "1px",
              borderColor: reggresionLineColor,
              borderStyle: "dashed",
            }}
          />
        </Unless>
      </Grid>
    </Grid>
  );
};

export default RegressionLineChart;
