import {
  performanceApi,
  AVG_SKELE_MOTION_FILE_NAME,
} from "../../services/performanceApi.service";
import dayjs from "dayjs";
import { defaultIfEmpty } from "../../utils/object";
import { MotionType } from "../common/MotionType";

interface GenerateAvgSkeleRequestPitchingBody {
  player_id: string;
  sport: "pitching";
  start_date: string;
  end_date: string;
  pitch_types: string;
  windup: string;
  velocity: string;
  limit: string;
  sync: string;
  vendor: string;
  home: string;
  batter_side: string;
  ivb: string;
  hb: string;
  prv: string;
  stuff: string;
  miss_dist: string;
  first_pitch: string;
  last_pitch: string;
  hor_miss: string;
  hor_miss_dist: string;
  spin_eff: string;
  innings: string;
  data_avg: string;
  trim_percent: string;
  test_query: string;
  save_name: string;
}

interface GenerateAvgSkeleRequestHittingBody {
  player_id: string;
  sport: "hitting";
  start_date: string;
  end_date: string;
  pitch_type_groups: string;
  exit_velo: string;
  exit_velo_filter: string;
  exit_velo_min: string;
  exit_velo_max: string;
  pitcher_hand: string;
  limit: string;
  sync: string;
  vendor: string;
  pitch_zone: string;
  bip: string;
  outcome: string;
  trajectory: string;
  count: string;
  batter_side: string;
  hit_bearing: string;
  launch_angle_filter: string;
  launch_angle_min: string;
  launch_angle_max: string;
  home: string;
  data_avg: string;
  trim_percent: string;
  is_miss: string;
  test_query: string;
  save_name: string;
}

type GenerateAvgSkeleRequestBody =
  | GenerateAvgSkeleRequestHittingBody
  | GenerateAvgSkeleRequestPitchingBody;

export type GenerateAvgSkeleResponse =
  | {
      status: "success";
      result: string;
      trials_count: number;
      // Completed Run: 10 (pitches|swings) queried and available. PlayerID: m9544b44aa377d00, Start Date: 2024-09-01, End Date: 2024-09-28. Download the file(s) from:
    }
  | {
      status: "error";
      error: {
        data: string;
        status: 404 | 500;
      };
    };

export interface GenerateAvgSkeleHittingParams
  extends Omit<
    Partial<GenerateAvgSkeleRequestHittingBody>,
    "pitch_type_groups" | "start_date" | "end_date"
  > {
  sport: "hitting";
  pitch_type_groups?: string[];
  date_range?: [dayjs.Dayjs, dayjs.Dayjs];
}

export interface GenerateAvgSkelePitchingParams
  extends Omit<
    Partial<GenerateAvgSkeleRequestPitchingBody>,
    "pitch_types" | "start_date" | "end_date" | "innings" | "test_query"
  > {
  sport: "pitching";
  pitch_types?: string[];
  date_range?: [dayjs.Dayjs, dayjs.Dayjs];
  innings?: string[];
}

export type GenerateAvgSkeleParams =
  | GenerateAvgSkeleHittingParams
  | GenerateAvgSkelePitchingParams;

export enum TrialType {
  Pitches = "Pitches",
  DailyAvg = "Daily Avg",
  CustomAvg = "Custom Avg",
}

export type AverageType = "custom" | "single_date" | "pitches";

const toISODate = (date?: dayjs.Dayjs) => date?.format("YYYY-MM-DD") ?? "";

const avgSkeleGenParamsToBody = (
  params: GenerateAvgSkeleParams
): Partial<GenerateAvgSkeleRequestBody> => {
  if (params.sport === "pitching") {
    const { pitch_types, date_range, innings, ...rest } = params;
    return {
      ...rest,
      pitch_types: pitch_types?.join(",") ?? "",
      innings: defaultIfEmpty("0", innings?.join(",")),
      start_date: toISODate(date_range?.[0]),
      end_date: toISODate(date_range?.[1]),
    };
  }

  const { pitch_type_groups, date_range, ...rest } = params;
  return {
    ...rest,
    pitch_type_groups: pitch_type_groups?.join(",") ?? "",
    start_date: toISODate(date_range?.[0]),
    end_date: toISODate(date_range?.[1]),
  };
};

const parseAvgSkeleGenResponse = (response: GenerateAvgSkeleResponse) => {
  if (response.status === "success") {
    return {
      ...response,
      trials_count: +(
        response.result.match(/Completed Run: (\d+) (pitches|swings)/)?.[1] ?? 0
      ),
    };
  }

  return response;
};

export const formatAvgSkeleFileName = (
  item: any,
  averageType: AverageType,
  movement: MotionType
) => {
  if (averageType === "custom") {
    return item.save_name;
  }

  if (movement === MotionType.Batting) {
    return `${item.start_date} ${item.pitch_type_groups_2} (n=${item.numPitches})`;
  }

  return `${item.start_date} ${item.pitch_types} ${item.velocity} (${item.windup}) (n=${item.numPitches})`;
};

const mapTrialType = (fileType: TrialType): AverageType => {
  const keys: Record<TrialType, AverageType> = {
    [TrialType.CustomAvg]: "custom",
    [TrialType.DailyAvg]: "single_date",
    [TrialType.Pitches]: "pitches",
  };

  return keys[fileType];
};

async function fetchAvgSkeleFiles(
  {
    playerId,
    motionType,
    trialType,
  }: {
    playerId: string;
    motionType: MotionType;
    trialType: TrialType;
  },
  baseQuery: any
) {
  const averageType = mapTrialType(trialType);
  const isBatting = motionType === MotionType.Batting;
  const movementType = isBatting ? "swing" : "pitch";
  const response: any = await baseQuery(
    `api/external/performance-dash/avgSkele/player/${playerId}/files?movementType=${movementType}`
  );
  const data: any[] = response.data || [];
  const filteredData = data.filter(
    (it) =>
      it.attributes.avg_type === averageType &&
      it.file.name.includes(AVG_SKELE_MOTION_FILE_NAME)
  );
  const mappedData = filteredData.map((it) => ({
    ...it,
    label: formatAvgSkeleFileName(
      { ...it.attributes, ...it.file },
      averageType,
      motionType
    ),
  }));

  return mappedData;
}

export const averageSkeleApi = performanceApi
  .enhanceEndpoints({
    addTagTypes: ["Performance/3DViewer/AverageSkele"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      validateAvgSkeleGen: builder.mutation<
        GenerateAvgSkeleResponse,
        GenerateAvgSkeleParams
      >({
        query: (params) => ({
          url: "api/external/performance-dash/avgSkeleGenerator",
          method: "POST",
          body: [{ ...avgSkeleGenParamsToBody(params), test_query: "1" }],
        }),
        transformResponse: (response: GenerateAvgSkeleResponse[]) =>
          parseAvgSkeleGenResponse(response[0]),
      }),
      generateAvgSkele: builder.mutation<
        GenerateAvgSkeleResponse[],
        GenerateAvgSkeleParams[]
      >({
        invalidatesTags: ["Performance/3DViewer/AverageSkele"],
        query: (params) => ({
          url: "api/external/performance-dash/avgSkeleGenerator",
          method: "POST",
          body: params.map((it) => ({
            ...avgSkeleGenParamsToBody(it),
            test_query: "0",
          })),
        }),
        transformResponse: (response: GenerateAvgSkeleResponse[]) =>
          response.map(parseAvgSkeleGenResponse),
      }),
      fetchAvgSkeleAvgFiles: builder.query<
        any[],
        {
          trialType: TrialType;
          playerId: string;
          motionType: MotionType;
        }
      >({
        providesTags: ["Performance/3DViewer/AverageSkele"],
        async queryFn(args, _api, _extraOptions, baseQuery) {
          try {
            const data = await fetchAvgSkeleFiles(args, baseQuery);
            return { data };
          } catch (error) {
            return Promise.reject({ error });
          }
        },
      }),
    }),
  });

export const {
  useValidateAvgSkeleGenMutation,
  useGenerateAvgSkeleMutation,
  useFetchAvgSkeleAvgFilesQuery,
} = averageSkeleApi;
