import React from "react";
import { Grid } from "@mui/material";

interface RegressionLegendItemsProps {
  label: string;
  width: string;
  fontSize?: React.CSSProperties["fontSize"];
  iconProps: {
    height: string;
    width: string;
    backgroundColor?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  };
}

const RegressionLegendItem = ({
  label,
  width,
  iconProps,
  fontSize = "12px",
}: RegressionLegendItemsProps) => (
  <Grid container flexDirection="row" alignItems="center" width={width}>
    <Grid item xs={1.5}>
      <div
        style={{
          height: iconProps.height,
          width: iconProps.width,
          borderWidth: iconProps.borderWidth,
          borderColor: iconProps.borderColor,
          borderStyle: iconProps.borderStyle,
          borderRadius: iconProps.borderRadius,
          backgroundColor: iconProps?.backgroundColor
            ? iconProps.backgroundColor
            : "white",
          marginRight: "4px",
        }}
      />
    </Grid>

    <Grid item xs={10.5}>
      <div style={{ fontSize }}>{label}</div>
    </Grid>
  </Grid>
);

export default RegressionLegendItem;
